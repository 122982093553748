import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import backicon from "../../assets/back.svg";
import plus from "../../assets/plus.svg";
import rarrow from "../../assets/rarrow.svg";
import { callApi } from "../utills/api";
import { showError, showSuccess } from "../utills/sweetAlert";

interface Measurement {
  sizeId: number;
  standeredSize: string | number;
  updateStatus: boolean;
}

interface RowData {
  labelId: number;
  labelName: string;
  measurements: Measurement[];
}

interface Sections {
  [key: string]: RowData[];
}

interface TableData {
  head: { id: number; size: string }[];
  body: Sections;
}

const Createmeasurmenttable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const TableId = location.state?.TableId;

  const [activeButton, setActiveButton] = useState<string>("IND");
  const [tableSelectData, setTableData] = useState<TableData>({
    head: [],
    body: {},
  });

  useEffect(() => {
    TableSelect();
  }, [activeButton]);

  const TableSelect = () => {
    const req = {
      id: TableId,
      countryType: activeButton,
    };

    callApi("POST", "measurementLabelSizeTabel.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          setTableData(response);
        }
      })
      .catch((err) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  };

  const handleInputChange = (sectionName:any, labelId:any, sizeId:any, event:any) => {
    const { value } = event.target;
    console.log(`Section: ${sectionName}, LabelId: ${labelId}, SizeId: ${sizeId}, New Value: ${value}`);

    setTableData((prevState) => {
      return {
        ...prevState,
        body: {
          ...prevState.body,
          [sectionName]: prevState.body[sectionName].map((item) => {
            if (item.labelId === labelId) {
              return {
                ...item,
                measurements: item.measurements.map((measurement) => {
                  if (measurement.sizeId === sizeId) {
                    return { ...measurement, standeredSize: value, updateStatus: true }; // Updating the value and status
                  }
                  return measurement;
                })
              };
            }
            return item;
          })
        }
      };
    });
  };

  const updateMeasurement = () => {
    const updatedMeasurements: { updateStatus: boolean; labelId: number; sizeId: number; value: string | number; countryCode: string; }[] = [];
    Object.values(tableSelectData.body).flat().forEach(row => {
      row.measurements.filter(m => m.updateStatus).forEach(m => {
        updatedMeasurements.push({
          updateStatus: true,
          labelId: row.labelId,
          sizeId: m.sizeId,
          value: m.standeredSize,
          countryCode: activeButton
        });
      });
    });

    const req = {
      measurementId: TableId,
      measurements: updatedMeasurements,
    };

    callApi("POST", "measurementTableUpdate.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
      showSuccess("Success!", "Measurements updated Successfully!");
      navigate("/layout/measurementdashboard")
          console.log("Measurements updated successfully");
        }
      })
      .catch((err) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  };
  const createNew = () =>{
    navigate("/layout/measurement")
      }

  return (
    <main>
      <Row>
        <Col lg="12" md="12">
          <div className="category-header">
            <h1 className="category-title">
              <img src={backicon} alt="" onClick={() => navigate("/layout/measurementdashboard")} />
              <span className="category-count">Create Measurements</span>
            </h1>
            <button className="create-new-btn"
            onClick={createNew}>
              <img src={plus} alt="plusicon" style={{ marginTop: "-2px" }} /> Create New
            </button>
          </div>
        </Col>
      </Row>

      <Row style={{ margin: "2% -1%" }}>
        <Col lg="4" md="4">
          <h4>Update your design parameters</h4>
        </Col>
        <Col lg="3" md="3">
          <div className="toggle-buttons">
            {["IND", "UK", "US", "EU"].map((country) => (
              <div
                key={country}
                className={`toggle-button ${activeButton === country ? "active" : ""}`}
                onClick={() => setActiveButton(country)}
              >
                {country}
              </div>
            ))}
          </div>
        </Col>
      </Row>

      <div className="flexnowrap ">
        <Table border={1} className="table-border">
          <thead>
            <tr>
              <th className="table-head">Sizes</th>
              {tableSelectData.head.map((header:any) => (
                <th key={header.id} className="table-head-one">{header.size}</th>
              ))}
            </tr>
          </thead>
          <tbody>
    {Object.entries(tableSelectData.body).map(([sectionName, rows]) => (
      <>
        <tr className="section-header">
          <th colSpan={tableSelectData.head.length + 1} className="table-head">{sectionName}</th>
        </tr>
        {rows.map((row) => (
          <tr key={row.labelId}>
            <td className="table-left">{row.labelName}</td>
           
            {row.measurements.map((measurement) => (
                                        <td key={measurement.sizeId} >
                                            <input
                                                type="text"
                                                className="form-control"
                                                style={{textAlign:"center",width:"60px"}}
                                                value={measurement.standeredSize || ""}
                                                onChange={(e) => handleInputChange(sectionName, row.labelId, measurement.sizeId, e)}
                                            />
                                        </td>
                                    ))}
          </tr>
        ))}
      </>
    ))}
  </tbody>
        </Table>
      </div>

      <button className="measurementsave"
      onClick={updateMeasurement}>
        <span>
          Save <img src={rarrow} alt="rarrow" />
        </span>
      </button>
    </main>
  );
};

export default Createmeasurmenttable;
