import React, { useEffect, useState } from "react";
import plus from "../../assets/plus.svg";
import UploadIcon from "../../assets/icons/upload-icon.svg";
import remove from "../../assets/remove.svg";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { callApi } from "../utills/api";
import rarrow from "../../assets/rarrow.svg";
import backicon from "../../assets/back.svg";
import CategoryModel from "./categorymodel";
import { showError, showSuccess } from "../utills/sweetAlert";
import "../auth/Elements3.css";
import sleev from "../../assets/sleev.svg";

interface MeasurementItem {
  categoryIcon: string | undefined;
  categoryName: string;
  name: string | undefined;
  icon: string | undefined;
  typeId: string;
  categoryId: string;
  id: string;
  title: string;
  labelName: string;
  labelImage?: string; // Add this to store image URL
  imageData?: string; // Add this to store base64 image data
  imageType?: string; // Add this to store image type
}

const Childcreate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { parentcategoryId } = location.state || {};
  const { data } = location.state || {};
  const [categoryDetails, setCategoryDetails] = useState<any>({});
  const [childLabelDetails, setChildLabelDetails] = useState<{
    [key: string]: string;
  }>({});
  const [dataDetails, setDataDetails] = useState({});
  const [measurements, setMeasurements] = useState<
    Record<string, MeasurementItem[]>
  >({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [EditCatData, setEditCatData] = useState<any>({});
  const[labelAddData,setlabelAddData] = useState<any>({});
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [selectedCategoryDetails, setSelectedCategoryDetails] =
    useState<any>(null);
  useEffect(() => {
    CategorySizeApi();
  }, []);

  const CategorySizeApi = () => {
    const req = {
      parentId: parentcategoryId,
    };
    callApi("POST", "categoryChildList.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response && response.data) {
          const {
            categoryDetails: newCategoryDetails,
            childLabelDetails: newChildLabelDetails,
            data,
          } = response;

          // Set dynamic categoryDetails and childLabelDetails
          setCategoryDetails(newCategoryDetails);
          setChildLabelDetails(newChildLabelDetails);

          // Transform measurements
          const transformedMeasurements: Record<string, MeasurementItem[]> = {};
          Object.keys(data).forEach((category) => {
            const items = data[category];
            items.forEach((item: MeasurementItem) => {
              if (!transformedMeasurements[category]) {
                transformedMeasurements[category] = [];
              }
              transformedMeasurements[category].push(item);
            });
          });
          setMeasurements(transformedMeasurements);
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });

    // let reqest = {
    //     categoryId: data
    //   };
    //   callApi("POST", "categorySingleSelect.php", reqest)
    //     .then((res) => res.data)
    //     .then((response) => {
    //       if (response) {
    //         setEditCatData("");
    //         console.log("EditCatData--->",EditCatData);
    //       }
    //     })
    //     .catch((err: any) => {
    //         console.error('API call error:', err.response ? err.response.data.error : err.message);
    //         showError("Failed",err.response ? err.response.data.error : err.message);
    //       });
  };

const handleAddMoreClick = (item: MeasurementItem) => {
    console.log("Selected item details:", item);
    setlabelAddData(item);
    categoryModel();
}

  const categoryModel = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    CategorySizeApi();
  };

  const backto = () => {
    navigate("/layout/category");
  };

  const handleViewDetails = (data: any,) => {
    navigate("/layout/mesurementlabel", { state: { data } });
  };

  return (
    <main>
      <Row>
        <Col lg="12" md="12">
          <div className="category-header">
            <h1 className="category-title">
            <img src={backicon} alt="" onClick={backto} />
              <span className="category-count">Create Category</span>
            </h1>
          </div>
        </Col>
      </Row>


      {Object.keys(measurements).length > 0 ? (
  Object.entries(measurements).map(([category, items]) => (
    <div className="dynamic section" key={category}>
      <div className="space">
        <h4>{category.charAt(0).toUpperCase() + category.slice(1)}</h4>
        {items.length > 0 ? (
          <Button
            className="unique-add-more-button"
            onClick={() => handleAddMoreClick(items[0])}
          >
            + Add More
          </Button>
        ) : (
          <p>No data found</p> // Display "No data found" if items array is empty
        )}
      </div>
      {items.length > 0 ? (
        <div className="paddingtop">
          <Row>
            {items.map((item) => (
              <Col md="3" lg="3" key={item.id} style={{ paddingBottom: "3%" }}
              onClick={() =>
                handleViewDetails(item.id)
              } >
                <div className="childlabelcard">
                  <div className="spacecenter">
                    <img
                      src={`https://seekreative.zerame.com/api/uploads/${item.categoryIcon}`}
                      style={{ width: "50px" }}
                      alt={item.categoryName}
                    />
                    <span className="category-name">{item.categoryName}</span>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      ) : null}
    </div>
  ))
) : (
  <p>No data found</p> // Show this if measurements object is empty
)}

      {isModalOpen && (
        <>
          <CategoryModel
            onClose={closeModal}
            editData={EditCatData}
            childlabelData={selectedValue}
            childData={selectedCategoryDetails}
            labelAddData={labelAddData}
          />
        </>
      )}
    </main>
  );
};

export default Childcreate;
