import React, { useState } from "react";
import "./element.css";
import { Button, CardBody, Col, Row } from "react-bootstrap";
import client from "../../assets/Group.svg";
import BarChart from "../../barchart";
import category from "../../assets/category.svg";
import upload from "../../assets/upload.svg";
import arrow from "../../assets/arrow.svg";
import plus from "../../assets/plus.svg";
import search from "../../assets/search.svg";
import fillter from "../../assets/fillter.svg";
import down from "../../assets/down.svg";
import leftarrow from "../../assets/leftarrow.svg";
import closeicon from "../../assets/close.svg";
import woman from "../../assets/woman.svg";
import blouse from "../../assets/blouse.svg";
import sleev from "../../assets/sleev.svg";
import remove from "../../assets/remove.svg";
import fileadd from "../../assets/file-add.svg";
import editicon from "../../assets/editicon.svg";
import file from "../../assets/svgcutone.svg";
import duplicate from "../../assets/duplicate.svg";
import rarrow from "../../assets/rarrow.svg";
import toggleIcon from "../../assets/Vector.svg";
import eyeIcon from "../../assets/view-icon1.svg";
import bodyDiagram from "../../assets/bodydigram.svg";
import UploadIcon from "../../assets/icons/upload-icon.svg";


const Elements = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    console.log("working");

    setIsModalOpen(true);
    console.log("isModalOpen", isModalOpen);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // add row remove //
  const [sizes, setSizes] = useState<string[]>([]);

  const handleAddSize = () => {
    setSizes([...sizes, ""]); // Add a new empty input field
  };

  const handleRemoveSize = (index: any) => {
    const newSizes = sizes.filter((_, i) => i !== index);
    setSizes(newSizes);
  };

  const handleSizeChange = (index: any, value: any) => {
    const newSizes = sizes.map((size, i) => (i === index ? value : size));
    setSizes(newSizes);
  };

  // image svg front back//
  const [frontFile, setFrontFile] = useState(null);
  const [backFile, setBackFile] = useState(null);

  // Handle File Change
  const handleFileChange = (e: any, type: any) => {
    const file = e.target.files[0];
    if (file && file.type === "image/svg+xml") {
      if (type === "front") {
        setFrontFile(file);
      } else {
        setBackFile(file);
      }
    } else {
      alert("Only SVG files are allowed.");
    }
  };

  //active btn in 3 buttons //
  const [activeButton, setActiveButton] = useState("uk");

  const activateButton = (buttonId: any) => {
    setActiveButton(buttonId);
  };

  // toggele visble image///
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div>
      <div className="main">
        <div className="container">
          <CardBody className="">
          
          </CardBody>
        </div>
      </div>
    </div>
  );
};

export default Elements;
