import React, { Component, useEffect } from "react";
import "./login.css";
import { CardBody, Col, Row } from "react-bootstrap";
import bg1 from "../../assets/bg1.svg";
import { callApi } from "../../components/utills/api";
import { showError, showSuccess } from "../../components/utills/sweetAlert";
import { Routes, Route, useNavigate } from "react-router-dom";
import SK from "../../assets/sk.svg";
import login1 from "../../assets/Login1.png";
import login2 from "../../assets/LOGIN2.png";

export default function Login() {
  const navigate = useNavigate();


  const [phoneNumber, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");

  const onchange = (e: any) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
      setEmailError("");
    } else if (name === "password") {
      setPassword(value);
      setPasswordError("");
    }
  };

  const handleClick = () => {
    console.log("surya");

    let emailError = "";
    let passwordError = "";

    if (!phoneNumber) {
      emailError = "Email is required";
    } 

    if (!password) {
      passwordError = "Password is required";
    }

    if (emailError || passwordError) {
      setEmailError(emailError);
      setPasswordError(passwordError);
      return;
    }

    let req = {
      phoneNumber,
      password,
    };

    callApi("POST", "login.php", req)
      .then((res) => res.data)
      .then((response) => {
        console.log("response",response);
        if (response["success"] === true) {
          console.log("response",response.response.auth_key);
    sessionStorage.setItem("authkey", response.response.auth_key);
    sessionStorage.setItem("name", response.response.user.firstName);
    sessionStorage.setItem("role", response.response.user.role);
          showSuccess("Success!", "Login Successfully!");

          setTimeout(() => {
            navigate("layout/dashboard");
          }, 1000);
        } else {
          showError("Failed", "Email & Password are not matched!");
        }
       
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <main className="overflow">
      <div>
        <Row>
          <Col lg="6" md="6" className="text-center bg-blue bgcol imgcol">
            <div className="bgcolor">
            <img src={login2} alt="" className="imgbg" />
            </div>
            
            <footer className="footer-container">
            <p className="powertext">Powered by</p>
            <img src={SK} alt="SEE KREATIVE Logo" className="footer-logo" />
            <div>
              <p className="rights-text">© 2024 All Rights Reserved</p>
              <p className="developer-text">Developed by M8 IT Solutions Pvt. Ltd.</p>
            </div>
          </footer>

          </Col>
          <Col lg="6" md="6" className=" bg-blue p-4 centercol alignitemcenter"
          style={{padding:"0 !important"}}>
            <CardBody className="">
              <div className="loginform">
                <h4 className="welcome text-center">Welcome Back!</h4>
                <h2 className="text-center">Karthika Shanmugam</h2>
                <p className="place text-center" >Enter your login details</p>
                <div className="paddingleft">
                  <div className="labeldiv pt-2 pb-2">
                    <label className="label">Username/Mobile Number </label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      required
                      value={phoneNumber}
                      onChange={onchange}
                    />
                    <div className="text-danger">{emailError}</div>
                  </div>
                  <div className="labeldiv pt-2 pb-2">
                    <label className="label">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      required
                      value={password}
                      onChange={onchange}
                    />
                    <div className="text-danger">{passwordError}</div>
                  </div>
                  <div className="forgotdiv pt-3 pb-3">
                  <div className="checkbox-container">
                <input type="checkbox"  name="#value"
                                        className="form-check-input "
                                        id="exampleCheck1" />
                <label style={{paddingLeft:"10px"}}>Remember me</label>
              </div>
              <div>
              <p className="forgot-password" >
                Forget Password?
              </p>
              </div>
              </div>
                  <div className="d-grid">
                    <button
                      className="form-control btn btn-primary subbutton" 
                      onClick={handleClick}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </CardBody>
          </Col>
        </Row>
      </div>
    </main>
  );
}
