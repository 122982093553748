import React, { useEffect, useState } from "react";
import plus from "../../assets/plus.svg";
import remove from "../../assets/remove.svg"; // Import the 'remove' icon
import { Row, Col } from "react-bootstrap";
import "../auth/Elements4.css"; // Updated CSS file name
import { useLocation, useNavigate } from "react-router-dom";
import backicon from "../../assets/back.svg";
import CategoryModel from "./categorymodel";
import { callApi } from "../utills/api";
import { showError, showSuccess } from "../utills/sweetAlert";

// Define the type for each size object
interface Size {
  id: string;
  size: string;
  categoryId: string;
  typeId: string;
  countryCode:string;
}

const MeasurementSize = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {};
  const { typeid } = location.state || {};

  
  console.log("size--data", data);
  console.log("typeid-->",typeid);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
    const [removedIds, setRemovedIds] = useState<string[]>([]);
  const [sizes, setSizes] = useState<Size[]>([]);
  const [EditCatData, setEditCatData] = useState<any>({});
  const [CategoryInfo, setCategoryInfo] = useState<any>({});
  const[labelAddData,setlabelAddData] = useState<any>({});



  useEffect(() => {
    CategorySizeApi();
    console.log("data____________", data);
  }, []);

  useEffect(() => {
    console.log("data____________", sizes);
  }, [sizes]);

  const CategorySizeApi = () => {
    const req = {
      categoryId: data,
      countryType:"IND"
    };
    callApi("POST", "sizeSelect.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          setSizes(response.data);
          setCategoryInfo(response.categoryInfo)
        }
        console.log("set--sizes",sizes);
        console.log("CategoryInfo--->",CategoryInfo);
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });

      let reqest = {
        categoryId: data
      };
      callApi("POST", "categorySingleSelect.php", reqest)
        .then((res) => res.data)
        .then((response) => {
          if (response) {
            setEditCatData("");
            console.log("EditCatData--->",EditCatData);
          }
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });
  };

  // Handle size input change
  const handleSizeChange = (index: number, value: string) => {
    const updatedSizes = [...sizes];
    updatedSizes[index].size = value;
    setSizes(updatedSizes);
  };
  const handleRemoveSize = (index: number) => {
    const removedId = sizes[index].id;
    const updatedSizes = sizes.filter((_, i) => i !== index);
    setSizes(updatedSizes);
    if (removedId && !removedIds.includes(removedId)) {
      setRemovedIds([...removedIds, removedId]);
    }
  };
  

  // Add a new size object to the list
  const handleAddSize = () => {
    setSizes([
      ...sizes,
      {
        id: "",
        size: "",
        categoryId: data || "", // Use the passed categoryId if available
        typeId: typeid || "",
        countryCode:activeButton,
      },
    ]);
  };

  const backto = () => {
    navigate("/layout/mesurementlabel", { state: { data } });

  };

  const categoryModel = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleViewSizeSave = () =>{
    const hasEmptySize = sizes.some((size) => size.size.trim() === "");
  if (hasEmptySize) {
    showError("Error!", "All sizes must be filled.");
    return;
  }
    const req = {
      id: data,
    typeId: typeid,
    sizes:sizes,
    // countryCode:activeButton,
    deletedIds:removedIds
    };
    console.log("request",req);
    
    callApi("POST", "categorySizeUpdate.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
      showSuccess("Success!", "Measurements Updated Successfully!");
      navigate("/layout/category");
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  }

  const [activeButton, setActiveButton] = useState("IND");

  const activateButton = (buttonId: any) => {
    console.log("buttonId",buttonId);
    setActiveButton(buttonId);

    const req = {
      categoryId: data,
      countryType:buttonId
    };
    callApi("POST", "sizeSelect.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          setSizes(response.data);
          setCategoryInfo(response.categoryInfo)
        }
        console.log("set--sizes",sizes);
        console.log("CategoryInfo--->",CategoryInfo);
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  };

  return (
    <div className="new-container-wrapper">
      <main className="">
        {/* Header Section */}
        <Row>
          <Col lg="12" md="12">
            <div className="category-header">
              <h1 className="category-title">
                <img src={backicon} alt="" onClick={backto} />
                <span className="category-count">Create Measurements</span>
              </h1>
              <button className="create-new-btn" onClick={categoryModel}>
                <img src={plus} alt="plusicon" style={{ marginTop: "-2px" }} />{" "}
                Create New
              </button>
            </div>
          </Col>
        </Row>

        <div className="new-sizes-container">
        {sizes.length > 0 ? (
          <>
          <Row>
            <Col lg="6" md="6">
              <div>
              <Row style={{margin:"2% -2%"}}>
              <Col lg="6" md="6">
                <h4>Create Sizes*</h4>
                </Col>
              <Col lg="6" md="6">
                <div className="toggle-buttons">
                <div
                        className={`toggle-button ${
                          activeButton === "IND" ? "active" : ""
                        }`}
                        onClick={() => activateButton("IND")}
                      >
                        IND
                      </div>
                      <div
                        className={`toggle-button ${
                          activeButton === "UK" ? "active" : ""
                        }`}
                        onClick={() => activateButton("UK")}
                      >
                        UK
                      </div>
                      <div
                        className={`toggle-button ${
                          activeButton === "US" ? "active" : ""
                        }`}
                        onClick={() => activateButton("US")}
                      >
                        US
                      </div>
                      <div
                        className={`toggle-button ${
                          activeButton === "EU" ? "active" : ""
                        }`}
                        onClick={() => activateButton("EU")}
                      >
                        EU
                      </div>
                    </div>
                    </Col>
                    </Row>
                <p>{CategoryInfo.categoryName}</p>
                {sizes.map((size, index) => (
                  <div key={index} className="new-size-input-wrapper">
                    <input
                      type="text"
                      className="new-size-input"
                      value={size.size}
                      onChange={(e) => handleSizeChange(index, e.target.value)}
                    />
                    <button
                      onClick={() => handleRemoveSize(index)}
                      className="new-remove-size-btn"
                    >
                      <img src={remove} alt="removeicon" />
                    </button>
                  </div>
                ))}

                <button className="new-add-size-btn" onClick={handleAddSize}>
                  <span className="new-add-icon">+</span> Add More
                </button>
              </div>
            </Col>
          </Row>

          <div className="unique-pagination-section">

<button
                  className="savecontinuebutton"
                  onClick={() => handleViewSizeSave()}
                >
                  <span className="">
                    Save 
                  </span>
                </button>


</div>
</>
 ): (
  <>
  <div>No data found</div>
  </>
)}



          {isModalOpen && (
            <>
                    <CategoryModel onClose={closeModal}  editData={EditCatData}
                       labelAddData={labelAddData} />

            </>
          )}
        </div>
      </main>
    </div>
  );
};

export default MeasurementSize;
