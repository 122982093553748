import React, { useEffect, useState } from "react";
import { Col, Dropdown, Form, Row } from "react-bootstrap";
import category from "../../assets/category.svg";
import upload from "../../assets/upload.svg";
import * as XLSX from "xlsx";
import { callApi } from "../utills/api";
import { showError, showSuccess } from "../utills/sweetAlert";
import { cloneDeep, isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";

interface LeadData {
  [key: string]: string | number | undefined;
}
const CategoryModel = ({
  onClose,
  editData,
  imgupdate,
  childlabelData,
  childData,
  plusId,
  labelAddData
}: any) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [attachments, setAttachments] = useState([
    {
      deleteStatus: false,
      imageType: "",
      imageData: "",
      updateStatus: false,
    },
  ]);
  const [labelFronterrorMessage, setLfErrorMessage] = useState("");
  const [labelFrontsuccessMessage, setLfSuccessMessage] = useState("");
  const [measurementErrorMessage, setMeasurementErrorMessage] = useState("");
  const [measurementSuccessMessage, setMeasurementSuccessMessage] =
    useState("");
  const [measurementsData, setMeasurementsData] = useState<{ size: string }[]>(
    []
  );
  const [excelData, setExcelData] = useState<LeadData[]>([]);
  const [state, setState] = useState({
    categoryname: "",
    categorytype: "",
    categoryicon: "",
    subcategory: "",
    mastercategory: "",
    parentcategory: "",
    labelfront: "",
    master: [],
    masterType: [],
    childLabel: [],
    parentDropDown: [],
    parentLable: "",
    parentValue: "",
    catPageData: [],
    parentDropDownSelect: [],
    childDropDownSelect: [],
    childLabelSelect: [],
    childlabel: "",
    catimage: "",
    catstatus: "",
    EditUpSts: false,
  });
  const [labelSleeveErrorMessage, setLabelSleeveErrorMessage] = useState("");
  const [labelSleeveSuccessMessage, setLabelSleeveSuccessMessage] =
    useState("");
  const [sleeveLabelsData, setSleeveLabelsData] = useState<
    { title: string; labelName: string }[]
  >([]);

  const [measurementSleeveErrorMessage, setMeasurementSleeveErrorMessage] =
    useState("");
  const [measurementSleeveSuccessMessage, setMeasurementSleeveSuccessMessage] =
    useState("");
  const [sleeveData, setSleeveData] = useState<{ size: string }[]>([]);

  const validateFile = (file: any) => {
    const validTypes = ["image/jpeg", "image/png", "image/svg+xml"];
    if (!validTypes.includes(file.type)) {
      return "Unsupported file format. Please upload JPEG, PNG, or SVG.";
    }
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      if (img.width !== 90 || img.height !== 90) {
        setErrorMessage("Image dimensions must be 90px by 90px.");
      } else {
        convertFileToBase64(file);
        setErrorMessage("");
      }
      URL.revokeObjectURL(img.src);
    };
    return null;
  };

  const convertFileToBase64 = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64data = reader.result as string; // Ensure it's a string

      setState((prevState) => ({
        ...prevState,
        categoryicon: base64data || "",
      }));

      const imageType = file.type.split("/")[1]; // This will give 'image'
      console.log("file.type", imageType);

      setAttachments((prevAttachments) => [
        ...prevAttachments.slice(0, prevAttachments.length - 1), // Remove the last element
        {
          ...prevAttachments[prevAttachments.length - 1],
          imageData: base64data, // Set the base64 data
          imageType: imageType,
          updateStatus: state.EditUpSts,
        },
      ]);

      setErrors((prevState) => ({
        ...prevState,
        categoryicon: "",
      }));
      setSuccessMessage("File uploaded successfully!");
    };
    reader.onerror = () => {
      setErrorMessage("Error converting file to Base64.");
    };
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setSuccessMessage("");
      const error = validateFile(file);
      if (error) {
        setErrorMessage(error);
        setState((prevState) => ({
          ...prevState,
          categoryicon: "",
        }));
      }
    }
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    if (file) {
      setSuccessMessage("");
      const error = validateFile(file);
      if (error) {
        setErrorMessage(error);
        setState((prevState) => ({
          ...prevState,
          categoryicon: "",
        }));
      }
    }
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const categoryOnchange = (event: any) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    if (event.target.name == "mastercategory") {
      showInputsParentApi(value);
      console.log("mastercategorycoming===00");
    }
    if (event.target.name == "parentcategory") {
      console.log("parentcategoryvalue-->", value);
      showInputsChildApi(value);
    }
  };

  const showInputsChildApi = (type: any) => {
    let request = {
      parentId: type,
    };
    callApi("POST", "categoryDropdownSelectsub.php", request)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          console.log("response.data", response.data);
          setState((prevState) => ({
            ...prevState,
            childDropDownSelect: response.data,
          }));
          console.log("childDropDownSelect--->", state.childDropDownSelect);
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  };

  const showInputsParentApi = (type: any) => {
    let request = {
      parentId: type,
    };
    callApi("POST", "categoryDropdownSelectParent.php", request)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          console.log("response.data", response.data);
          setState((prevState) => ({
            ...prevState,
            parentDropDownSelect: response.data,
          }));
          console.log("parentDropDownSelect--->", state.parentDropDownSelect);
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  };
  const [Errors, setErrors] = useState({
    categoryname: "",
    categorytype: "",
    categoryicon: "",
  });
  // 2nd model function //
  const handleSecondOpenModal = (event: any) => {
    const form = event.target.form;
    if (form.checkValidity() === false) {
      console.log("Form validation failed.");
      return;
    }
    event.preventDefault();
    if (!state.categoryicon) {
      setErrors((prevState) => ({
        ...prevState,
        categoryicon: "Category Icon is required",
      }));
    }
    console.log("excelData----->", excelData);
    console.log("measurementsData--->", measurementsData);
    console.log("state====>", state);
    if (state.categoryicon && state.categorytype && state.categoryname) {
      let request = {
        categoryName: state.categoryname,
        categoryType: state.categorytype,
        parentId: !isEmpty(state.subcategory)
          ? state.subcategory
          : !isEmpty(state.parentcategory)
          ? state.parentcategory
          : state.mastercategory,
        saveStatus: "draft",
        labels: [...excelData, ...sleeveLabelsData],
        sizes: [...measurementsData, ...sleeveData],
        attachments: attachments,
        childLabelId: state.childlabel,
      };
      console.log("req___", request);
      callApi("POST", "catergoryCreate.php", request)
        .then((res) => res.data)
        .then((response) => {
          if (response) {
            showSuccess("Success!", "Category Created Successfully!");
            onClose();
            // if (state.categorytype == "4") {
            //   navigate("/layout/childlabel", {
            //     state: { parentcategoryId: state.subcategory },
            //   });
            // }
            setState((prevState) => ({
              ...prevState,
              parentDropDown: response.data,
              parentLable: response.label,
            }));
            setIsModalOpen(false);
            setState((prevState) => ({
              ...prevState,
              categoryicon: "",
              categoryname: "",
              categorytype: "",
              subcategory: "",
              mastercategory: "",
              parentcategory: "",
              labelfront: "",
            }));
            setSuccessMessage("");
          }
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });
    }
  };

  const handleSavePublish = (event: any) => {
    const form = event.target.form;
    if (form.checkValidity() === false) {
      console.log("Form validation failed.");
      return;
    }
    event.preventDefault();
    if (!state.categoryicon) {
      setErrors((prevState) => ({
        ...prevState,
        categoryicon: "Category Icon is required",
      }));
    }
    console.log("excelData----->", excelData);
    console.log("measurementsData--->", measurementsData);
    console.log("state====>", state);

    if (state.categoryicon && state.categorytype && state.categoryname) {
      let request = {
        categoryName: state.categoryname,
        categoryType: state.categorytype,
        parentId: !isEmpty(state.subcategory)
          ? state.subcategory
          : !isEmpty(state.parentcategory)
          ? state.parentcategory
          : state.mastercategory,
        saveStatus: "live",
        labels: [...excelData, ...sleeveLabelsData],
        sizes: [...measurementsData, ...sleeveData],
        attachments: attachments,
        childLabelId: state.childlabel,
      };
      console.log("req___", request);
      callApi("POST", "catergoryCreate.php", request)
        .then((res) => res.data)
        .then((response) => {
          if (response) {
            showSuccess("Success!", "Category Created Successfully!");
            onClose();
            // if (state.categorytype == "4") {
            //   navigate("/layout/childlabel", {
            //     state: { parentcategoryId: state.subcategory },
            //   });
            // }
            setState((prevState) => ({
              ...prevState,
              parentDropDown: response.data,
              parentLable: response.label,
            }));
            setIsModalOpen(false);
            setState((prevState) => ({
              ...prevState,
              categoryicon: "",
              categoryname: "",
              categorytype: "",
              subcategory: "",
              mastercategory: "",
              parentcategory: "",
              labelfront: "",
            }));
            setSuccessMessage("");
          }
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });
    }
  };

  // label front //
  const validateFileExcelFormat = (file: File) => {
    const allowedExtensions = /(\.xls|\.xlsx|\.csv)$/i;
    if (!allowedExtensions.exec(file.name)) {
      return "Only .xls, .xlsx, and .csv files are allowed.";
    }
    return "";
  };

  useEffect(() => {
    console.log("use effect set excelData:", excelData);
  }, [excelData]);

  const handleFileUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target && e.target.result) {
        let data: string | ArrayBuffer = e.target.result;
        const isCSV = file.name.endsWith(".csv");
        const workbook = isCSV
          ? XLSX.read(data, { type: "string" })
          : XLSX.read(data, { type: "binary" });

        let labelsArray: { title: string; labelName: string }[] = [];

        workbook.SheetNames.forEach((sheetName) => {
          const worksheet = workbook.Sheets[sheetName];
          const sheetData: any[][] = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
          });
          console.log(`Data from ${sheetName}:`, sheetData);
          const extractedData = sheetData
            .slice(1)
            .map((row: any[]) => {
              if (row[1] && row[2]) {
                return {
                  title: row[1] as string,
                  labelName: row[2] as string,
                };
              }
              return null;
            })
            .filter(
              (item): item is { title: string; labelName: string } =>
                item !== null
            ); // Type guard to ensure non-null values
          console.log("extractedData--->", extractedData);
          labelsArray.push(...extractedData);
        });
        console.log("set labelsArray:", labelsArray);
        setExcelData(labelsArray);
        console.log("setExcelData====>", excelData);
        setLfSuccessMessage("File uploaded successfully!");
        setLfErrorMessage("");
      }
    };
    if (file.name.endsWith(".csv")) {
      reader.readAsText(file);
    } else {
      reader.readAsBinaryString(file);
    }
  };

  const handleFileChangeLabelF = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setLfSuccessMessage("");
      const error = validateFileExcelFormat(file);
      if (error) {
        setLfErrorMessage(error);
      } else {
        handleFileUpload(file);
      }
    }
  };
  const handleDragOverexcel = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  const handleDropexcel = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const error = validateFileExcelFormat(file);
      if (error) {
        setLfErrorMessage(error);
      } else {
        handleFileUpload(file);
      }
    }
  };

  // measurement //
  const validateFileExcelFormatMeasurement = (file: File) => {
    const allowedExtensions = /(\.xls|\.xlsx|\.csv)$/i;
    if (!allowedExtensions.exec(file.name)) {
      return "Only .xls, .xlsx, and .csv files are allowed.";
    }
    return "";
  };

  // const handleFileUploadMeasurement = (file: File) => {
  //   const reader = new FileReader();
  //   reader.onload = (e) => {
  //     if (e.target && e.target.result) {
  //       let data: string | ArrayBuffer = e.target.result;
  //       const isCSV = file.name.endsWith(".csv");
  //       const workbook = isCSV
  //         ? XLSX.read(data, { type: "string" })
  //         : XLSX.read(data, { type: "binary" });
  //       let sizesArray: { size: string }[] = [];
  //       workbook.SheetNames.forEach((sheetName) => {
  //         const worksheet = workbook.Sheets[sheetName];
  //         const sheetData: any[][] = XLSX.utils.sheet_to_json(worksheet, {
  //           header: 1,
  //         });
  //         console.log(`Data from ${sheetName}:`, sheetData); // Check the JSON converted data
  //         const extractedData = sheetData
  //           .slice(1)
  //           .map((row: any[]) => {
  //             if (row[1]) {
  //               // Check if size exists (assuming column B contains sizes)
  //               return {
  //                 size: row[1] as string,
  //               };
  //             }
  //             return null;
  //           })
  //           .filter((item): item is { size: string } => item !== null); // Type guard to ensure non-null values
  //         sizesArray.push(...extractedData);
  //       });
  //       console.log("Formatted Measurements Data (Sizes):", sizesArray);
  //       setMeasurementsData(sizesArray);
  //       setMeasurementSuccessMessage("File uploaded successfully!");
  //       setMeasurementErrorMessage("");
  //     }
  //   };
  //   if (file.name.endsWith(".csv")) {
  //     reader.readAsText(file);
  //   } else {
  //     reader.readAsBinaryString(file);
  //   }
  // };

  const handleFileUploadMeasurement = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target && e.target.result) {
        let data: string | ArrayBuffer = e.target.result;
        const isCSV = file.name.endsWith(".csv");
        const workbook = isCSV
          ? XLSX.read(data, { type: "string" })
          : XLSX.read(data, { type: "binary" });

        let sizesArray: any[] = []; // Adjusted to accept any object structure

        workbook.SheetNames.forEach((sheetName) => {
          const worksheet = workbook.Sheets[sheetName];
          const sheetData: any[][] = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
          });
          console.log(`Data from ${sheetName}:`, sheetData); // Check the JSON converted data

          // Assuming the first row is the header
          const headers = sheetData[0];
          const extractedData = sheetData.slice(1).map((row: any[]) => {
            let rowObj: any = {};
            row.forEach((cell, index) => {
              if (headers[index]) {
                // Ensuring the header exists for the column
                rowObj[headers[index]] = cell;
              }
            });
            return rowObj;
          });

          sizesArray.push(...extractedData);
        });

        console.log("Formatted Measurements Data (Sizes):", sizesArray);
        setMeasurementsData(sizesArray);
        setMeasurementSuccessMessage("File uploaded successfully!");
        setMeasurementErrorMessage("");
      }
    };
    if (file.name.endsWith(".csv")) {
      reader.readAsText(file);
    } else {
      reader.readAsBinaryString(file);
    }
  };

  const handleFileChangeMeasurement = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setMeasurementSuccessMessage("");
      const error = validateFileExcelFormatMeasurement(file);
      if (error) {
        setMeasurementErrorMessage(error);
      } else {
        handleFileUploadMeasurement(file);
      }
    }
  };

  const handleDragOverMeasurement = (
    event: React.DragEvent<HTMLDivElement>
  ) => {
    event.preventDefault();
  };

  const handleDropMeasurement = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const error = validateFileExcelFormatMeasurement(file);
      if (error) {
        setMeasurementErrorMessage(error);
      } else {
        handleFileUploadMeasurement(file);
      }
    }
  };

  // label sleev//
  const validateFileExcelFormatSleeve = (file: File) => {
    const allowedExtensions = /(\.xls|\.xlsx|\.csv)$/i;
    if (!allowedExtensions.exec(file.name)) {
      return "Only .xls, .xlsx, and .csv files are allowed.";
    }
    return "";
  };

  const handleFileUploadSleeve = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target && e.target.result) {
        let data: string | ArrayBuffer = e.target.result;
        const isCSV = file.name.endsWith(".csv");
        const workbook = isCSV
          ? XLSX.read(data, { type: "string" })
          : XLSX.read(data, { type: "binary" });
        let sleeveLabelsArray: { title: string; labelName: string }[] = [];
        workbook.SheetNames.forEach((sheetName) => {
          const worksheet = workbook.Sheets[sheetName];
          const sheetData: any[][] = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
          });
          console.log(`Data from ${sheetName}:`, sheetData);
          const extractedData = sheetData
            .slice(1)
            .map((row: any[]) => {
              if (row[1]) {
                return {
                  title: row[1] as string,
                  labelName: row[2] as string,
                };
              }
              return null;
            })
            .filter(
              (item): item is { title: string; labelName: string } =>
                item !== null
            ); // Filter out null values
          sleeveLabelsArray.push(...extractedData);
        });
        console.log("Formatted Sleeve Labels Data:", sleeveLabelsArray);
        setSleeveLabelsData(sleeveLabelsArray);
        setLabelSleeveSuccessMessage("File uploaded successfully!");
        setLabelSleeveErrorMessage("");
      }
    };
    if (file.name.endsWith(".csv")) {
      reader.readAsText(file);
    } else {
      reader.readAsBinaryString(file);
    }
  };
  const handleFileChangeSleeve = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setLabelSleeveSuccessMessage("");
      const error = validateFileExcelFormatSleeve(file);
      if (error) {
        setLabelSleeveErrorMessage(error);
      } else {
        handleFileUploadSleeve(file);
      }
    }
  };
  const handleDragOverSleeve = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  const handleDropSleeve = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const error = validateFileExcelFormatSleeve(file);
      if (error) {
        setLabelSleeveErrorMessage(error);
      } else {
        handleFileUploadSleeve(file);
      }
    }
  };
  //measurement sleev //
  const validateFileExcelFormatMeasurementSleeve = (file: File) => {
    const allowedExtensions = /(\.xls|\.xlsx|\.csv)$/i;
    if (!allowedExtensions.exec(file.name)) {
      return "Only .xls, .xlsx, and .csv files are allowed.";
    }
    return "";
  };

  const handleFileUploadMeasurementSleeve = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target && e.target.result) {
        let data: string | ArrayBuffer = e.target.result;
        const isCSV = file.name.endsWith(".csv");
        const workbook = isCSV
          ? XLSX.read(data, { type: "string" })
          : XLSX.read(data, { type: "binary" });

        let sizesArray: any[] = [];

        workbook.SheetNames.forEach((sheetName) => {
          const worksheet = workbook.Sheets[sheetName];
          const sheetData: any[][] = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
          });
          console.log(`Data from ${sheetName}:`, sheetData);

          const headers = sheetData[0];
          const extractedData = sheetData.slice(1).map((row: any[]) => {
            let rowObj: any = {};
            row.forEach((cell, index) => {
              if (headers[index]) {
                // Ensuring the header exists for the column
                rowObj[headers[index]] = cell;
              }
            });
            return rowObj;
          });

          sizesArray.push(...extractedData);
        });
        console.log("Formatted Measurements Data (Sizes):", sizesArray);

        setSleeveData(sizesArray);
        setMeasurementSleeveSuccessMessage("File uploaded successfully!");
        setMeasurementSleeveErrorMessage("");
      }
    };
    if (file.name.endsWith(".csv")) {
      reader.readAsText(file);
    } else {
      reader.readAsBinaryString(file);
    }
  };
  const handleFileChangeMeasurementSleeve = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setMeasurementSleeveSuccessMessage("");
      const error = validateFileExcelFormatMeasurementSleeve(file);
      if (error) {
        setMeasurementSleeveErrorMessage(error);
      } else {
        handleFileUploadMeasurementSleeve(file);
      }
    }
  };
  const handleDragOverMeasurementSleeve = (
    event: React.DragEvent<HTMLDivElement>
  ) => {
    event.preventDefault();
  };
  const handleDropMeasurementSleeve = (
    event: React.DragEvent<HTMLDivElement>
  ) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const error = validateFileExcelFormatMeasurementSleeve(file);
      if (error) {
        setMeasurementSleeveErrorMessage(error);
      } else {
        handleFileUploadMeasurementSleeve(file);
      }
    }
  };
  useEffect(() => {
    getCategoryDetails();
  }, []);
  useEffect(() => {
    console.log("Updated state:", state.catPageData);
  }, [state]);
  const getCategoryDetails = () => {
    callApi("GET", "masterSelect.php", "")
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          setState((prevState: any) => ({
            ...prevState,
            master: response,
          }));
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });

    callApi("GET", "categoryDropdownSelectMaster.php", "")
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          setState((prevState: any) => ({
            ...prevState,
            masterType: response.data,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });

    callApi("GET", "chidLabelSelect.php", "")
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          setState((prevState: any) => ({
            ...prevState,
            childLabelSelect: response,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("Coming --- editData---imgupdate", editData, imgupdate);
    setState((prevState) => ({
      ...prevState,
      categoryname: editData.categoryName,
      categorytype: editData.typeId,
      mastercategory: editData.masterId,
      parentcategory: editData.parentId,
      subcategory: editData.subId,
      catimage: editData.image,
      catstatus: editData.status,
      childlabel: editData.childId,
      EditUpSts: imgupdate,
    }));
    if (editData.masterId) {
      showInputsParentApi(editData.masterId);
    }
    if (editData.parentId) {
      showInputsChildApi(editData.parentId);
    }
    setShowImage(imgupdate);
    console.log("Updated localEditData--->", editData);
  }, [editData]);

  useEffect(() => {
    console.log("coming---childlabelData--->", childlabelData);
    console.log("coming---childData--->", childData);
    if (
      childlabelData &&
      Object.keys(childlabelData).length > 0 &&
      childData &&
      Object.keys(childData).length > 0
    ) {
      SetChildData();
    }
    if (plusId) {
      setState((prevState) => ({
        ...prevState,
        categorytype: plusId,
      }));
    }
  }, [childData]);

  useEffect(() => {
    console.log("coming---labelAddData--->", labelAddData);
    if(labelAddData.typeId=="4"){
    setState((prevState) => ({
      ...prevState,
      categorytype: labelAddData.typeId,
      childlabel:labelAddData.childId,
      mastercategory:labelAddData.masterId
    }));
    console.log("state--->",state.mastercategory);
    console.log("state--->categorytype",state.categorytype);
  }   
  }, [labelAddData]);

  const SetChildData = () => {
    if (childData.masterId) {
      showInputsParentApi(childData.masterId);
    }
    if (childData.parentId) {
      showInputsChildApi(childData.parentId);
    }
    setState((prevState) => ({
      ...prevState,
      categorytype: childData.typeId,
      mastercategory: childData.masterId,
      parentcategory: childData.parentId,
      subcategory: childData.subId,
      childlabel: childlabelData,
    }));
  };

  const updateCategoryDetails = (id: any) => {
    let request = {
      id: editData.id,
      categoryName: state.categoryname,
      attachments: attachments,
      labels: [...excelData, ...sleeveLabelsData],
      sizes: [...measurementsData, ...sleeveData],
    };

    callApi("POST", "categoryUpdate.php", request)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          showSuccess("Success!", "Category Updated Successfully!");
          onClose();
          setIsModalOpen(false);
          setState((prevState) => ({
            ...prevState,
            categoryicon: "",
            categoryname: "",
            categorytype: "",
            subcategory: "",
            mastercategory: "",
            parentcategory: "",
            labelfront: "",
          }));
          setSuccessMessage("");
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  };
  const [showImage, setShowImage] = useState(false);
  const handleImageClose = () => {
    setShowImage(false);
  };

  const onCloseModel = () => {
    setState((prevState) => ({
      ...prevState,
      categoryicon: "",
      categoryname: "",
      categorytype: "",
      subcategory: "",
      mastercategory: "",
      parentcategory: "",
      labelfront: "",
      EditUpSts: false,
    }));
    setSuccessMessage("");
    onClose();
  };

  return (
    <>
      <button className="modal-close" onClick={onCloseModel}>
        &times;
      </button>
      <div className="modal-backdrop">
        <div className="modal">
          <div className="modal-header">
            <h2>Create Category</h2>
          </div>
          <div className="modal-content">
            <form className="formalign">
              <div className="form-group">
                <label>Category Name*</label>
                <input
                  type="text"
                  className={`form-control ${state.categoryname ? 'active-border' : ''}`}
                  name="categoryname"
                  required
                  onChange={categoryOnchange}
                  value={state.categoryname}
                  minLength={3}
                  maxLength={50}
                />
                {Errors.categoryname && (
                  <div className="error-message">{Errors.categoryname}</div>
                )}
              </div>
              <div className="form-group">
                <label>Category Type*</label>
                <Form.Select
                  aria-label="e.g., Parent Category, Sub Category..."
                  className="form-control"
                  name="categorytype"
                  required
                  onChange={categoryOnchange}
                  value={state.categorytype}
                  disabled={state.EditUpSts == true && state.categorytype != ""}
                >
                  <option value="" selected disabled>
                    Select Category
                  </option>
                  {state.master.map((master: any) => (
                    <option key={master.value} value={master.value}>
                      {master.label}
                    </option>
                  ))}
                </Form.Select>
                {Errors.categorytype && (
                  <div className="error-message">{Errors.categorytype}</div>
                )}
              </div>
              {/* //master  category  // */}
              {["2", "3", "4"].includes(state.categorytype) && (
                <>
                  <div className="form-group">
                    <label>Select Master Category*</label>
                    <Form.Select
                      aria-label="Master Category Name"
                      className="form-control"
                      name="mastercategory"
                      value={state.mastercategory}
                      onChange={categoryOnchange}
                      required
                      disabled={state.EditUpSts && state.mastercategory !== ""}
                    >
                      <option value="" selected disabled>
                        Select Master Category
                      </option>
                      {state.masterType.map((master: any) => (
                        <option key={master.value} value={master.value}>
                          {master.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </>
              )}
              {/* //Parent category  // */}
              {["3", "4"].includes(state.categorytype) && (
                <>
                  <div className="form-group">
                    <label>Select Parent Category*</label>
                    <Form.Select
                      aria-label="Parent Category Name"
                      className="form-control"
                      name="parentcategory"
                      value={state.parentcategory}
                      onChange={categoryOnchange}
                      required
                      disabled={state.EditUpSts && state.parentcategory !== ""}
                    >
                      <option value="" selected disabled>
                        Select Parent Category
                      </option>
                      {state.parentDropDownSelect.map((master: any) => (
                        <option key={master.value} value={master.value}>
                          {master.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </>
              )}
              {/* //Sub category  // */}
              {["4"].includes(state.categorytype) && (
                <>
                  <div className="form-group">
                    <label>Select Sub Category*</label>
                    <Form.Select
                      aria-label="Parent Category Name"
                      className="form-control"
                      name="subcategory"
                      value={state.subcategory}
                      onChange={categoryOnchange}
                      required
                      disabled={state.EditUpSts && state.subcategory !== ""}
                    >
                      <option value="" selected disabled>
                        Select Sub Category
                      </option>
                      {state.childDropDownSelect.map((master: any) => (
                        <option key={master.value} value={master.value}>
                          {master.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </>
              )}
              {/* //child label category  // */}
              {["4"].includes(state.categorytype) && (
                <>
                  <div className="form-group">
                    <label>Choose Child Label</label>
                    <Form.Select
                      aria-label="Parent Category Name"
                      className="form-control"
                      name="childlabel"
                      value={state.childlabel}
                      onChange={categoryOnchange}
                      required
                      disabled={state.EditUpSts && state.childlabel !== ""}
                    >
                      <option value="" selected disabled>
                        Select Child Label
                      </option>
                      {state.childLabelSelect &&
                        state.childLabelSelect.map((child: any) => (
                          <option key={child.value} value={child.value}>
                            {child.label}
                          </option>
                        ))}
                    </Form.Select>
                  </div>
                </>
              )}
              {/* //Category icon // */}
              {["1", "2", "3", "4"].includes(state.categorytype) && (
                <>
                  <div className="form-group">
                    <label>Category Icon</label>

                    {!showImage && (
                      <div
                        className="file-upload"
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                      >
                        <input
                          type="file"
                          id="file-input"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />

                        <div
                          className="upload-icon"
                          onClick={() => {
                            const fileInput =
                              document.getElementById("file-input");
                            if (fileInput) {
                              fileInput.click();
                            } else {
                              console.error("File input not found");
                            }
                          }}
                        >
                          <img src={upload} alt="Upload Icon" />
                        </div>

                        <div className="drag-drop-area">
                          <p>
                            Drag and Drop file here or{" "}
                            <label
                              htmlFor="file-input"
                              style={{ cursor: "pointer" }}
                            >
                              Choose file
                            </label>
                          </p>
                          <p className="file-info">
                            Supported formats: JPEG, PNG, SVG (90 px X 90 px)
                          </p>
                          {errorMessage && (
                            <div className="error-message">{errorMessage}</div>
                          )}
                          {successMessage && (
                            <div className="success-message">
                              {successMessage}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {editData.id &&
                      showImage && ( // Show the image if available and visible
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <img
                            src={editData.image}
                            alt="Uploaded Icon"
                            style={{ width: "100px" }}
                          />
                          <span
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "-10px",
                              cursor: "pointer",
                              fontSize: "18px",
                              backgroundColor: "white",
                              borderRadius: "50%",
                              padding: "2px 6px",
                              boxShadow: "0 0 5px rgba(0,0,0,0.3)",
                            }}
                            onClick={handleImageClose}
                          >
                            &times;
                          </span>
                        </div>
                      )}
                  </div>
                  {Errors.categoryicon && (
                    <div className="error-message 2">{Errors.categoryicon}</div>
                  )}
                </>
              )}
              {/* //labels front // */}
              {["2"].includes(state.categorytype) && (
                <>
                  <div className="form-group">
                    <label style={{ display: "-webkit-inline-box" }}>
                      Labels{" "}
                    </label>
                    <button className="sample">
                      <a
                        href="/labels.xlsx"
                        download="Sample_Labels_Format.xlsx"
                        className="atag"
                      >
                        Download Sample Labels Format
                      </a>
                    </button>
                    <div
                      className="file-upload"
                      onDrop={handleDropexcel}
                      onDragOver={handleDragOverexcel}
                    >
                      <input
                        type="file"
                        id="file-inputlabelF"
                        style={{ display: "none" }}
                        onChange={handleFileChangeLabelF}
                      />
                      <div
                        className="upload-icon"
                        onClick={() => {
                          const fileInput =
                            document.getElementById("file-inputlabelF");
                          if (fileInput) {
                            fileInput.click();
                          } else {
                            console.error("File input not found");
                          }
                        }}
                      >
                        <img src={upload} alt="Upload Icon" />
                      </div>
                      <div
                        className="drag-drop-area"
                        onDrop={handleDropexcel}
                        onDragOver={handleDragOverexcel}
                      >
                        <p>
                          Drag and Drop file here or{" "}
                          <label
                            htmlFor="file-inputlabelF"
                            style={{ cursor: "pointer" }}
                          >
                            Choose file
                          </label>
                        </p>
                        {labelFronterrorMessage && (
                          <div className="error-message">
                            {labelFronterrorMessage}
                          </div>
                        )}
                        {labelFrontsuccessMessage && (
                          <div className="success-message">
                            {labelFrontsuccessMessage}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* //measurement // */}
              {["2"].includes(state.categorytype) && (
                <>
                  <div className="form-group">
                    <label style={{ display: "-webkit-inline-box" }}>
                      Measurements
                    </label>
                    <button className="sample">
                      <a
                        href="/sizes.xlsx"
                        download="Sample_Measurement_Format.xlsx"
                        className="atag"
                      >
                        Download Sample Measurement Format
                      </a>
                    </button>
                    <div
                      className="file-upload"
                      onDrop={handleDropMeasurement}
                      onDragOver={handleDragOverMeasurement}
                    >
                      <input
                        type="file"
                        id="file-inputMeasurement"
                        style={{ display: "none" }}
                        onChange={handleFileChangeMeasurement}
                      />
                      <div
                        className="upload-icon"
                        onClick={() => {
                          const fileInput = document.getElementById(
                            "file-inputMeasurement"
                          );
                          if (fileInput) {
                            fileInput.click();
                          } else {
                            console.error("File input not found");
                          }
                        }}
                      >
                        <img src={upload} alt="Upload Icon" />
                      </div>
                      <div
                        className="drag-drop-area"
                        onDrop={handleDropMeasurement}
                        onDragOver={handleDragOverMeasurement}
                      >
                        <p>
                          Drag and Drop file here or{" "}
                          <label
                            htmlFor="file-inputMeasurement"
                            style={{ cursor: "pointer" }}
                          >
                            Choose file
                          </label>
                        </p>
                        {measurementErrorMessage && (
                          <div className="error-message">
                            {measurementErrorMessage}
                          </div>
                        )}
                        {measurementSuccessMessage && (
                          <div className="success-message">
                            {measurementSuccessMessage}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* //label sleev // */}
              {["4"].includes(state.categorytype) && (
                <>
                  <div className="form-group">
                    <label style={{ display: "-webkit-inline-box" }}>
                      Label Sleeve
                    </label>
                    <button className="sample">
                      <a
                        href="/labels.xlsx"
                        download="Sample_Labels_Format.xlsx"
                        className="atag"
                      >
                        Download Sample Labels Format
                      </a>
                    </button>
                    <div
                      className="file-upload"
                      onDrop={handleDropSleeve}
                      onDragOver={handleDragOverSleeve}
                    >
                      <input
                        type="file"
                        id="file-inputSleeve"
                        style={{ display: "none" }}
                        onChange={handleFileChangeSleeve}
                      />
                      <div
                        className="upload-icon"
                        onClick={() => {
                          const fileInput =
                            document.getElementById("file-inputSleeve");
                          if (fileInput) {
                            fileInput.click();
                          } else {
                            console.error("File input not found");
                          }
                        }}
                      >
                        <img src={upload} alt="Upload Icon" />
                      </div>
                      <div
                        className="drag-drop-area"
                        onDrop={handleDropSleeve}
                        onDragOver={handleDragOverSleeve}
                      >
                        <p>
                          Drag and Drop file here or{" "}
                          <label
                            htmlFor="file-inputSleeve"
                            style={{ cursor: "pointer" }}
                          >
                            Choose file
                          </label>
                        </p>
                        {labelSleeveErrorMessage && (
                          <div className="error-message">
                            {labelSleeveErrorMessage}
                          </div>
                        )}
                        {labelSleeveSuccessMessage && (
                          <div className="success-message">
                            {labelSleeveSuccessMessage}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* //measurement sleev // */}
              {["4"].includes(state.categorytype) && (
                <>
                  <div className="form-group">
                  <label style={{ display: "-webkit-inline-box" }}>Measurement Sleeve</label>
                  <button className="sample">
                      <a
                        href="/sizes.xlsx"
                        download="Sample_Measurement_Format.xlsx"
                        className="atag"
                      >
                        Download Sample Measurement Format
                      </a>
                    </button>
                    <div
                      className="file-upload"
                      onDrop={handleDropMeasurementSleeve}
                      onDragOver={handleDragOverMeasurementSleeve}
                    >
                      <input
                        type="file"
                        id="file-inputMeasurementSleeve"
                        style={{ display: "none" }}
                        onChange={handleFileChangeMeasurementSleeve}
                      />
                      <div
                        className="upload-icon"
                        onClick={() => {
                          const fileInput = document.getElementById(
                            "file-inputMeasurementSleeve"
                          );
                          if (fileInput) {
                            fileInput.click();
                          } else {
                            console.error("File input not found");
                          }
                        }}
                      >
                        <img src={upload} alt="Upload Icon" />
                      </div>
                      <div
                        className="drag-drop-area"
                        onDrop={handleDropMeasurementSleeve}
                        onDragOver={handleDragOverMeasurementSleeve}
                      >
                        <p>
                          Drag and Drop file here or{" "}
                          <label
                            htmlFor="file-inputMeasurementSleeve"
                            style={{ cursor: "pointer" }}
                          >
                            Choose file
                          </label>
                        </p>
                        {measurementSleeveErrorMessage && (
                          <div className="error-message">
                            {measurementSleeveErrorMessage}
                          </div>
                        )}
                        {measurementSleeveSuccessMessage && (
                          <div className="success-message">
                            {measurementSleeveSuccessMessage}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="modal-footer">
                {!editData?.id ? (
                  <>
                    <button
                      type="submit"
                      className="save-button"
                      onClick={handleSecondOpenModal}
                    >
                      Save
                    </button>
                    <button
                      type="submit"
                      className="save-publish-button"
                      onClick={handleSavePublish}
                    >
                      Save & Publish
                    </button>
                  </>
                ) : (
                  <></>
                )}

                {editData.id && (
                  <button
                    type="button"
                    style={{ width: "80%" }}
                    className="save-publish-button"
                    onClick={() => updateCategoryDetails(editData.id)}
                  >
                    Update
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryModel;
