import React, { useState } from "react";
import Select from 'react-select'
import "./Elements1.css";
import { Col, Table, Row, Form, Button, Badge } from "react-bootstrap";
import design1 from "../../assets/designs/image1.svg";
import design2 from "../../assets/designs/image2.svg";
import design3 from "../../assets/designs/image3.svg";
import design4 from "../../assets/designs/image4.svg";
import design5 from "../../assets/designs/image5.svg";
import design6 from "../../assets/designs/image6.svg";
import design7 from "../../assets/designs/image7.svg";
import design8 from "../../assets/designs/image8.svg";
import design9 from "../../assets/designs/image9.svg";
import design10 from "../../assets/designs/image10.svg";
import design11 from "../../assets/designs/image11.svg";
import RightArrow from "../../assets/icons/right-arrow.svg";
import BlouseIcon from "../../assets/icons/blouse-icon.svg";
import NormalSleeve from "../../assets/icons/normal-sleeve.svg";
import CustomSize from "../../assets/icons/custom-size.svg";
import UploadIcon from "../../assets/icons/upload-icon.svg";
import PreviewIcon from "../../assets/icons/preview-icon.svg";
import AddIcon from "../../assets/icons/add-icon.svg";
import MinusIcon from "../../assets/icons/substract-icon.svg";
import DesignIcon from "../../assets/icons/design-icon.svg";
import logosvg from "../../assets/sidebar/logo.svg";
import UndoIcon from "../../assets/icons/undo-icon.svg";
import RedoIcon from "../../assets/icons/redo-icon.svg";
import leftarrow from "../../assets/icons/menu-left.svg";
import DesignMenu from "../../assets/icons/design-menu.svg";
import SleeveMenu from "../../assets/icons/sleeve-menu-icon.svg";
import NeckMenu from "../../assets/icons/neck-menu-icon.svg";
import VNeckIcon from "../../assets/icons/v-neck.svg";
import SizeMenu from "../../assets/icons/size-menu-icon.svg";
import MaterialMenu from "../../assets/icons/material-menu-icon.svg";
import ProfileImage from "../../assets/designs/image-profile.svg";
import EditProfile from "../../assets/icons/edit-profile.svg";
import ChangePassword from "../../assets/icons/change-password.svg";
import AboutUs from "../../assets/icons/about-us.svg";
import TermsAndConditions from "../../assets/icons/terms-condition.svg";
import PrivacyPolicy from "../../assets/icons/privacy-policy.svg";
import HelpCenter from "../../assets/icons/help-center.svg";
import Announcement from "../../assets/icons/announcement.svg";
import TotalClients from "../../assets/icons/total-client.svg";
import TotalOrders from "../../assets/icons/total-orders.svg";
import TotalItems from "../../assets/icons/total-items.svg";
import TotalYears from "../../assets/icons/total-years.svg";
import OverAllRating from "../../assets/icons/overall-rating.svg";
import FeedbackCollected from "../../assets/icons/feedback-collected.svg";
import FeedbackPerDay from "../../assets/icons/feedback-per-day.svg";

const Elements1 = () => {
  const designs = [
    design1, design2, design3, design4, design5, design1, design2, design3, design4, design5, design1, design2, design3, design4, design5
  ];

  const data = [
    { item: "Sleeve Length", standard: "5", min: "0.8", max: "1.2" },
    { item: "Sleeve End Circumference", standard: "5.2", min: "0.8", max: "1.2" },
    { item: "Mid hand Round", standard: "6", min: "0.8", max: "1.2" },
    { item: "Cap Height", standard: "2.6", min: "0.8", max: "1.2" },
  ];

  const data1 = [
    { item: "Body Height", standard: "7", min: "0.8", max: "1.2" },
    { item: "Upper Chest", standard: "9.5", min: "0.8", max: "1.2" },
    { item: "Waist", standard: "9.5", min: "0.8", max: "1.2" },
    { item: "Neck Height", standard: "3.75", min: "0.8", max: "1.2" },
    { item: "Neck Width", standard: "2", min: "0.8", max: "1.2" },
    { item: "Neck Mid Curve", standard: "1", min: "0.8", max: "1.2" },
    { item: "Arm Hole Length", standard: "5", min: "0.8", max: "1.2" },
    { item: "Arm Hole Mid Curve", standard: "1", min: "0.8", max: "1.2" },
    { item: "Half Shoulder", standard: "7.3", min: "0.8", max: "1.2" },
    { item: "Shoulder Width", standard: "2.3", min: "0.8", max: "1.2" },
    { item: "Appex Point Length", standard: "7", min: "0.8", max: "1.2" },
    { item: "Dart 1 Mid Point", standard: "2", min: "0.8", max: "1.2" },
  ];

  const options = [
    { value: 'Cotton', label: 'Cotton' },
    { value: 'Raymond', label: 'Raymond' },
    { value: 'Linen', label: 'Linen' },
    { value: 'Silk', label: 'Silk' },
    { value: 'Muslin', label: 'Muslin' }
  ]

  const labels = [
    { value: 'Lapel', label: 'Lapel' },
    { value: 'Notch', label: 'Notch' },
  ]

  const [selectedCategory, setSelectedCategory] = useState('Bride');
  const categories = ['Bride', 'Simple', 'Buttas', 'Work'];
  const imageData = {
    'Bride': [design6, design7, design8, design9, design10, design6, design7, design8, design9, design10, design11],
    'Simple': [design6, design7, design8, design9, design10, design6, design7, design8, design9, design10, design11],
    'Buttas': [design6, design7, design8, design9, design10, design6, design7, design8, design9, design10, design11],
    'Work': [design6, design7, design8, design9, design10, design6, design7, design8, design9, design10, design11],
  };

  const [selectedSleeve, setSelectedSleeve] = useState('Normal Sleeve');
  const sleeveTypes = [
    { id: 1, name: 'Normal Sleeve', icon: VNeckIcon },
    { id: 2, name: 'Short Sleeve', icon: VNeckIcon },
    { id: 3, name: 'Puff Sleeve', icon: VNeckIcon },
    { id: 4, name: 'Full Sleeve', icon: VNeckIcon },
  ];

  const [selectedNeck, setSelectedNeck] = useState('V-neck');
  const neckTypes = [
    { id: 1, name: 'V-neck', icon: VNeckIcon },
    { id: 2, name: 'Scoop', icon: VNeckIcon },
    { id: 3, name: 'Square', icon: VNeckIcon },
    { id: 4, name: 'High neck', icon: VNeckIcon },
    { id: 5, name: 'Keyhole', icon: VNeckIcon },
  ];

  const [selectedMaterial, setSelectedMaterial] = useState('Silk');
  const materialTypes = [
    { id: 1, name: 'Silk' },
    { id: 2, name: 'Cotton' },
    { id: 3, name: 'Linen' },
    { id: 4, name: 'Velvet' },
    { id: 5, name: 'Poplin' },
    { id: 6, name: 'Rubia' },
    { id: 7, name: 'Bhandani' },
    { id: 8, name: 'Rayon' },
    { id: 9, name: 'Lace' },
    { id: 10, name: 'Kalamkari' },
  ];

  const clients = [
    {
      id: 1,
      name: 'Caroline Heaney',
      email: 'carolineheaney@gmail.com',
      phone: '9845657865',
      imageUrl: ProfileImage,
    },
    {
      id: 2,
      name: 'Caroline Heaney',
      email: 'carolineheaney@gmail.com',
      phone: '9845657865',
      imageUrl: ProfileImage,
    },
    {
      id: 3,
      name: 'Caroline Heaney',
      email: 'carolineheaney@gmail.com',
      phone: '9845657865',
      imageUrl: ProfileImage,
    },
    {
      id: 4,
      name: 'Caroline Heaney',
      email: 'carolineheaney@gmail.com',
      phone: '9845657865',
      imageUrl: ProfileImage,
    },
    {
      id: 5,
      name: 'Caroline Heaney',
      email: 'carolineheaney@gmail.com',
      phone: '9845657865',
      imageUrl: ProfileImage,
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 3;

  const handleClick = (page: any) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const reviews = [
    {
      customer: 'Nike Adesanoye',
      date: '5 June, 2024',
      rating: 4.5,
      comment: 'Knowledgeable about products and able to assist with styling suggestions. Quick and efficient checkout process.',
    },
    {
      customer: 'Nike Adesanoye',
      date: '5 June, 2024',
      rating: 4.5,
      comment: 'Knowledgeable about products and able to assist with styling suggestions. Quick and efficient checkout process.',
    },
    {
      customer: 'Nike Adesanoye',
      date: '5 June, 2024',
      rating: 4.5,
      comment: 'Knowledgeable about products and able to assist with styling suggestions. Quick and efficient checkout process.',
    }
  ];

  const renderStars = (rating: any) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <>
        {[...Array(fullStars)].map((_, i) => (
          <span key={i} className="star full">★</span>
        ))}
        {halfStar && <span className="star half">★</span>}
        {[...Array(emptyStars)].map((_, i) => (
          <span key={i} className="star empty">★</span>
        ))}
      </>
    );
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: '50px',
      height: 'auto',
      padding: '10px 0px',
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: '#0072B933',
      borderRadius: '30px',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: '#0072B9',
      fontSize: '16px',
      fontWeight: '500',
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      backgroundColor: '#0072B9',
      borderRadius: '50%',
      width: '14px',
      height: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2px',
      cursor: 'pointer',
      margin: '0px 8px 0px 8px',
      ':before': {
        content: '""',
        width: '8px',
        height: '1.5px',
        backgroundColor: 'white',
        transform: 'rotate(45deg)',
        position: 'absolute',
      },
      ':after': {
        content: '""',
        width: '8px',
        height: '1.5px',
        backgroundColor: 'white',
        transform: 'rotate(-45deg)',
        position: 'absolute',
      },
      ':hover': {
        backgroundColor: '#0072B9', // Keep it the same on hover
      },
    }),
  };

  const customLabelStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: '50px',
      height: 'auto',
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: '#0072B933',
      borderRadius: '30px',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: '#0072B9',
      fontSize: '16px',
      fontWeight: '500',
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      backgroundColor: '#0072B9',
      borderRadius: '50%',
      width: '14px',
      height: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2px',
      cursor: 'pointer',
      margin: '0px 8px 0px 8px',
      ':before': {
        content: '""',
        width: '8px',
        height: '1.5px',
        backgroundColor: 'white',
        transform: 'rotate(45deg)',
        position: 'absolute',
      },
      ':after': {
        content: '""',
        width: '8px',
        height: '1.5px',
        backgroundColor: 'white',
        transform: 'rotate(-45deg)',
        position: 'absolute',
      },
      ':hover': {
        backgroundColor: '#0072B9', // Keep it the same on hover
      },
    }),
  };

  return (
 <>
 </>
  );
}

export default Elements1;
