import React, { useState } from "react";

import "./model.css";

import CreateIcon from '../../assets/create-icon.svg';
import Icon from '../../assets/icon.svg';

const Model = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    console.log("working");

    setIsModalOpen(true);
    console.log("isModalOpen", isModalOpen);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div>
        <button className="cardbutton" onClick={handleOpenModal}>
          <span>
            <img src={CreateIcon} alt="" />{" "}
          </span>{" "}
          <p>Create New</p>
        </button>
      </div>

      {isModalOpen && (
        <>
         
        </>
      )}

      {isModalOpen && (
        <>
          <div className="modal-backdrop">
            <div className="modal">
              <div className="tablecon">
                <div className="first">
                  Create Measurement
                  <button className="modal-close" onClick={handleCloseModal}>
            <img src={Icon} alt="" />
          </button>
                </div>
                <div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Model;
