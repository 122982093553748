import React, { useEffect, useState } from "react";
import { Col, Dropdown, Form, Row } from "react-bootstrap";
import upload from "../../assets/upload.svg";
import { callApi } from "../utills/api";
import { showError, showSuccess } from "../utills/sweetAlert";
import { useNavigate } from "react-router-dom";


const CategoryChildModel = ({
  closeChildModal,
  editData,
  imgupdate,
}: any) => {
  const [isChildModalOpen, setisChildModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [attachments, setAttachments] = useState([
    {
      deleteStatus: false,
      imageType: "",
      imageData: "",
      updateStatus: false,
    },
  ]);

  const [state, setState] = useState({
    childname: "",
    categoryicon: "",
    mastercategory: "",
    master: [],
    masterType: [],
    EditUpSts: false,
  });

  const validateFile = (file: any) => {
    const validTypes = ["image/jpeg", "image/png", "image/svg+xml"];
    if (!validTypes.includes(file.type)) {
      return "Unsupported file format. Please upload JPEG, PNG, or SVG.";
    }
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      if (img.width !== 90 || img.height !== 90) {
        setErrorMessage("Image dimensions must be 90px by 90px.");
      } else {
        convertFileToBase64(file);
        setErrorMessage("");
      }
      URL.revokeObjectURL(img.src);
    };
    return null;
  };

  const convertFileToBase64 = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64data = reader.result as string; // Ensure it's a string
      setState((prevState) => ({
        ...prevState,
        categoryicon: base64data || "",
      }));
      const imageType = file.type.split("/")[1]; // This will give 'image'
      console.log("file.type", imageType);
      setAttachments((prevAttachments) => [
        ...prevAttachments.slice(0, prevAttachments.length - 1), // Remove the last element
        {
          ...prevAttachments[prevAttachments.length - 1],
          imageData: base64data, // Set the base64 data
          imageType: imageType,
          updateStatus: state.EditUpSts,
        },
      ]);
      setErrors((prevState) => ({
        ...prevState,
        categoryicon: "",
      }));
      setSuccessMessage("File uploaded successfully!");
    };
    reader.onerror = () => {
      setErrorMessage("Error converting file to Base64.");
    };
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setSuccessMessage("");
      const error = validateFile(file);
      if (error) {
        setErrorMessage(error);
        setState((prevState) => ({
          ...prevState,
          categoryicon: "",
        }));
      }
    }
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    if (file) {
      setSuccessMessage("");
      const error = validateFile(file);
      if (error) {
        setErrorMessage(error);
        setState((prevState) => ({
          ...prevState,
          categoryicon: "",
        }));
      }
    }
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const categoryOnchange = (event: any) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const [Errors, setErrors] = useState({
    categoryicon: "",
  });
  // 2nd model function //
  const handleSecondOpenModal = (event: any) => {
    const form = event.target.form;
    if (form.checkValidity() === false) {
      console.log("Form validation failed.");
      return;
    }
    event.preventDefault();
    if (!state.categoryicon) {
      setErrors((prevState) => ({
        ...prevState,
        categoryicon: "Category Icon is required",
      }));
    }
    if (state.categoryicon  && state.childname) {
      let request = {
        name: state.childname,
        masterId:state.mastercategory,
        attachments: attachments,
      };
      console.log("req___", request);
      callApi("POST", "childLabelCreate.php", request)
        .then((res) => res.data)
        .then((response) => {
          if (response) {
            showSuccess("Success!", "Child Label Created Successfully!");
            closeChildModal();
            setisChildModalOpen(false);
            setState((prevState) => ({
              ...prevState,
              categoryicon: "",
              childname: "",
              mastercategory: "",
            }));
            setSuccessMessage("");
          }
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });
    }
  };

  const handleSavePublish = (event: any) => {
    const form = event.target.form;
    if (form.checkValidity() === false) {
      console.log("Form validation failed.");
      return;
    }
    event.preventDefault();
    if (!state.categoryicon) {
      setErrors((prevState) => ({
        ...prevState,
        categoryicon: "Category Icon is required",
      }));
    }
    // if (state.categoryicon && state.categorytype && state.childname) {
    //   let request = {
    //     childname: state.childname,
    //     categoryType: state.categorytype,
    //     parentId: !isEmpty(state.subcategory)
    //       ? state.subcategory
    //       : !isEmpty(state.parentcategory)
    //       ? state.parentcategory
    //       : state.mastercategory,
    //     saveStatus: "live",
    //     labels: [...excelData, ...sleeveLabelsData],
    //     sizes: [...measurementsData, ...sleeveData],
    //     attachments: attachments,
    //     childLabelId: state.childlabel,
    //   };
    //   console.log("req___", request);
    //   callApi("POST", "catergoryCreate.php", request)
    //     .then((res) => res.data)
    //     .then((response) => {
    //       if (response) {
    //         showSuccess("Success!", "Category Created Successfully!");
    //         closeChildModal();
    //         if (state.categorytype == "4") {
    //           navigate("/layout/childlabel", {
    //             state: { parentcategoryId: state.subcategory },
    //           });
    //         }
    //         setState((prevState) => ({
    //           ...prevState,
    //           parentDropDown: response.data,
    //           parentLable: response.label,
    //         }));
    //         setisChildModalOpen(false);
    //         setState((prevState) => ({
    //           ...prevState,
    //           categoryicon: "",
    //           childname: "",
    //           categorytype: "",
    //           subcategory: "",
    //           mastercategory: "",
    //           parentcategory: "",
    //           labelfront: "",
    //         }));
    //         setSuccessMessage("");
    //       }
    //     })
    //     .catch((err: any) => {
    //       console.error(
    //         "API call error:",
    //         err.response ? err.response.data.error : err.message
    //       );
    //       showError(
    //         "Failed",
    //         err.response ? err.response.data.error : err.message
    //       );
    //     });
    // }
  };
 
  useEffect(() => {
    getCategoryDetails();
    console.log("coming--->");
    
  }, []);

  const getCategoryDetails = () => {
    console.log("api call");
    
    callApi("GET", "categoryDropdownSelectMaster.php", "")
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          setState((prevState: any) => ({
            ...prevState,
            masterType: response.data,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("Coming --- CHILD_---editData---imgupdate", editData, imgupdate);
    setState((prevState) => ({
      ...prevState,
      childname: editData.childName,
      mastercategory: editData.masterId,
      catimage: editData.image,
      EditUpSts: imgupdate,
    }));
    setShowImage(imgupdate);
  }, [editData]);


  const updateCategoryDetails = (id: any) => {
    let request = {
      id: editData.id,
      labelName: state.childname,
      attachments: attachments,
    };

    callApi("POST", "childLabelUpdate.php", request)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          showSuccess("Success!", "Category Child Updated Successfully!");
          closeChildModal();
          setisChildModalOpen(false);
          setState((prevState) => ({
            ...prevState,
            categoryicon: "",
            childname: "",
          }));
          setSuccessMessage("");
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  };
  const [showImage, setShowImage] = useState(false);
  const handleImageClose = () => {
    setShowImage(false);
  };

  const closeChildModalModel = () => {
    setState((prevState) => ({
      ...prevState,
      categoryicon: "",
      childname: "",
      mastercategory: "",
      EditUpSts: false,
    }));
    setSuccessMessage("");
    closeChildModal();
  };

  return (
    <>
      <button className="modal-close" onClick={closeChildModalModel}>
        &times;
      </button>
      <div className="modal-backdrop">
        <div className="modal">
          <div className="modal-header">
            <h2> Create Child Label</h2>
          </div>
          <div className="modal-content">
            <form className="formalign">
              <div className="form-group">
                <label>Child Label Name*</label>
                <input
                  type="text"
                  className="form-control"
                  name="childname"
                  required
                  onChange={categoryOnchange}
                  value={state.childname}
                  minLength={3}
                  maxLength={50}
                />
               
              </div>
                  <div className="form-group">
                    <label>Select Master Category*</label>
                    <Form.Select
                      aria-label="Master Category Name"
                      className="form-control"
                      name="mastercategory"
                      value={state.mastercategory}
                      onChange={categoryOnchange}
                      required
                      disabled={state.EditUpSts && state.mastercategory !== ""}
                    >
                      <option value="" selected disabled>
                        Select Master Category
                      </option>
                      {state.masterType.map((master: any) => (
                        <option key={master.value} value={master.value}>
                          {master.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
             
              {/* //Category icon // */}
            
                  <div className="form-group">
                    <label>Category Icon</label>

                    {!showImage && (
                      <div
                        className="file-upload"
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                      >
                        <input
                          type="file"
                          id="file-input"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />

                        <div
                          className="upload-icon"
                          onClick={() => {
                            const fileInput =
                              document.getElementById("file-input");
                            if (fileInput) {
                              fileInput.click();
                            } else {
                              console.error("File input not found");
                            }
                          }}
                        >
                          <img src={upload} alt="Upload Icon" />
                        </div>

                        <div className="drag-drop-area">
                          <p>
                            Drag and Drop file here or{" "}
                            <label
                              htmlFor="file-input"
                              style={{ cursor: "pointer" }}
                            >
                              Choose file
                            </label>
                          </p>
                          <p className="file-info">
                            Supported formats: JPEG, PNG, SVG (90 px X 90 px)
                          </p>
                          {errorMessage && (
                            <div className="error-message">{errorMessage}</div>
                          )}
                          {successMessage && (
                            <div className="success-message">
                              {successMessage}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {editData.id &&
                      showImage && ( // Show the image if available and visible
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <img
                            src={editData.image}
                            alt="Uploaded Icon"
                            style={{ width: "100px" }}
                          />
                          <span
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "-10px",
                              cursor: "pointer",
                              fontSize: "18px",
                              backgroundColor: "white",
                              borderRadius: "50%",
                              padding: "2px 6px",
                              boxShadow: "0 0 5px rgba(0,0,0,0.3)",
                            }}
                            onClick={handleImageClose}
                          >
                            &times;
                          </span>
                        </div>
                      )}
                  </div>
                  {Errors.categoryicon && (
                    <div className="error-message 2">{Errors.categoryicon}</div>
                  )}
             
              <div className="modal-footer">
                {!editData?.id ? (
                  <>
                    <button
                      type="submit"
                      className="save-buttonchildcancel"
                      onClick={closeChildModalModel}
                    >
                     Cancel
                    </button>
                    <button
                      type="submit"
                      className="save-buttonchild"
                      onClick={handleSecondOpenModal}
                    >
                      Save 
                    </button>
                  </>
                ) : (
                  <></>
                )}

                {editData.id && (
                  <button
                    type="button"
                    style={{ width: "80%" }}
                    className="save-publish-button"
                    onClick={() => updateCategoryDetails(editData.id)}
                  >
                    Update
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryChildModel;
