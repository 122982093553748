import React, { useEffect, useState } from "react";
import { Col, Dropdown, Form, Row } from "react-bootstrap";
import category from "../../assets/category.svg";
import upload from "../../assets/upload.svg";
import * as XLSX from "xlsx";
import { callApi } from "../utills/api";
import plus from "../../assets/plus.svg";
import search from "../../assets/search.svg";
import fillter from "../../assets/fillter.svg";
import plusicon from "../../assets/addplus.svg";
import CategoryModel from "./categorymodel";
import { useNavigate } from "react-router-dom";
import { showAlert, showError, showSuccess } from "../utills/sweetAlert";
import CategoryChildModel from "./childmodel";

interface LeadData {
  [key: string]: string | number | undefined;
}
const Category = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChildModalOpen, setIsChildModalOpen] = useState(false);
  const [EditCatData, setEditCatData] = useState<any>({});
  const [ChildEditCatData, setEditCatChildData] = useState<any>({});
  const[labelAddData,setlabelAddData] = useState<any>({});
  const [imgUpdateStatus, setimgUpdateStatus] = useState(false);

  useEffect(() => {
    CategorypageShowApi();
  }, []);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleChildOpenModal = () => {
    setIsChildModalOpen(true);
  };

  const handleOpenModalone = (typeId: any) => {
    console.log("typeId-->,typeId", typeId);

    if (Array.isArray(typeId) && typeId.length > 0) {
      setCatIdPlus(typeId[0].typeId); // Assuming typeId is an array of objects and you need the first object's typeId
      console.log("catIdPlus--->", catIdPlus);
    }
    setIsModalOpen(true);
  };

  type Category = {
    id: string;
    name: string;
    createdAt: string;
    status: string;
  };

  const [categories, setCategories] = useState<{
    masterCategories: Category[];
    parentCategories: Category[];
    subCategories: Category[];
    childCategories: Category[];
  }>({
    masterCategories: [],
    parentCategories: [],
    subCategories: [],
    childCategories: [],
  });

  //page show api//
  const CategorypageShowApi = () => {
    callApi("GET", "categorySelect.php", "")
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          setCategories(response);
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  };

  const hasCategories =
    categories.masterCategories.length > 0 ||
    categories.parentCategories.length > 0 ||
    categories.subCategories.length > 0 ||
    categories.childCategories.length > 0;

  const allCategoryCount =
    (categories.childCategories ? categories.childCategories.length : 0) +
    (categories.subCategories ? categories.subCategories.length : 0) +
    (categories.parentCategories ? categories.parentCategories.length : 0) +
    (categories.masterCategories ? categories.masterCategories.length : 0);
  // search //

  const [masterSearchTerm, setMasterSearchTerm] = useState<string>("");
  const [parentSearchTerm, setParentSearchTerm] = useState<string>("");
  const [subSearchTerm, setSubSearchTerm] = useState<string>("");
  const [childSearchTerm, setChildSearchTerm] = useState<string>("");
  const [catIdPlus, setCatIdPlus] = useState<string>("");

  const handleMasterSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMasterSearchTerm(e.target.value);
  };
  const handleParentSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setParentSearchTerm(e.target.value);
  };
  const handleSubSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubSearchTerm(e.target.value);
  };
  const handleChildSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChildSearchTerm(e.target.value);
  };
  const filteredMasterCategories = categories.masterCategories.filter(
    (category: any) =>
      category.name.toLowerCase().includes(masterSearchTerm.toLowerCase())
  );
  const filteredParentCategories = categories.parentCategories.filter(
    (category: any) =>
      category.name.toLowerCase().includes(parentSearchTerm.toLowerCase()) ||
      category.masterName.toLowerCase().includes(parentSearchTerm.toLowerCase())
  );
  // Filter functions
  const filteredSubCategories = categories.subCategories.filter(
    (category: any) =>
      category.name.toLowerCase().includes(subSearchTerm.toLowerCase()) ||
      category.masterName.toLowerCase().includes(subSearchTerm.toLowerCase()) ||
      category.parentName.toLowerCase().includes(subSearchTerm.toLowerCase())
  );
  const filteredChildCategories = categories.childCategories.filter(
    (category: any) =>
      category.name.toLowerCase().includes(childSearchTerm.toLowerCase()) ||
      category.masterName
        .toLowerCase()
        .includes(childSearchTerm.toLowerCase()) ||
      category.parentName
        .toLowerCase()
        .includes(childSearchTerm.toLowerCase()) ||
      category.subName.toLowerCase().includes(childSearchTerm.toLowerCase())
  );

  const closeModal = () => {
    setIsModalOpen(false);
    CategorypageShowApi();
    setEditCatData("");
    setimgUpdateStatus(false);
    setCatIdPlus("");
    setlabelAddData("");
  };

  const closeChildModal = () => {
    setIsChildModalOpen(false);
    CategorypageShowApi();
    setEditCatChildData("");
    setimgUpdateStatus(false);
    setCatIdPlus("");
  };

  const handleViewDetails = (data: any, typeid: any) => {
    navigate("/layout/mesurementlabel", { state: { data, typeid } });
  };

  const handlePublishapi = async (data: any) => {
    const isComfirm = await showAlert(
      "Please Confirm",
      "Are you sure you want to Published this Category?",
      "Yes",
      "No"
    );
    if (isComfirm) {
      Publishupdate(data);
    }
  };

  const Publishupdate = (data: any) => {
    let req = {
      id: data,
      status: "live",
    };
    callApi("POST", "categoryStatusUpdate.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success == true) {
          showSuccess("Success!", "Category Published Successfully!");
          CategorypageShowApi();
        } else {
          console.log("response.error", response.error);
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  };

  //category delete//
  const handleDeleteCategory = async (id: any) => {
    const isComfirm = await showAlert(
      "Please Confirm",
      "Are you sure you want to Delete?",
      "Yes",
      "No"
    );
    if (isComfirm) {
      let req = {
        id: id,
      };
      callApi("POST", "categoryDelete.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response) {
            showSuccess("Success!", "Category Deleted Successfully!");
            CategorypageShowApi();
          }
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });
    }
  };

  // child delete //
  const handleChilcDeleteCategory = async (id: any) => {
    const isComfirm = await showAlert(
      "Please Confirm",
      "Are you sure you want to Delete?",
      "Yes",
      "No"
    );
    if (isComfirm) {
      let req = {
        id: id,
      };
      callApi("POST", "childLabelDelete.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response) {
            showSuccess("Success!", "Child Category Deleted Successfully!");
            CategorypageShowApi();
          }
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });
    }
  };

  const handleEditCategory = (id: any) => {
    let req = {
      categoryId: id,
    };
    console.log("req--->", req);

    callApi("POST", "categorySingleSelect.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          setEditCatData(response.data);
          console.log("EditCatData--->", EditCatData);
          setIsModalOpen(true);
          setimgUpdateStatus(true);
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  };

  const handleChildEditCategory = (id: any) => {
    console.log("id-->",id); 
    let req = {
      labelId: id,
    };
    console.log("req--->", req);

    callApi("POST", "childLabelSingleSelect.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          setEditCatChildData(response.data);
          console.log("EditCatData--->", ChildEditCatData);
          setIsChildModalOpen(true);
          setimgUpdateStatus(true);
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  };

  const handleViewPage = (id: any) => {
    navigate("/layout/childlabel", {
      state: { parentcategoryId: id },
    });
  };

  const handleAddChildLabel = (category:any) =>{
    console.log("category--->",category);
    setlabelAddData(category);
    setIsModalOpen(true);
  }

  return (
    <div className="mcdiv">
      {!hasCategories && (
        <Row>
          <Col
            lg="12"
            md="12"
            className="d-flex justify-content-center align-items-center vh-100"
          >
            <div className="cardcontainer">
              <img src={category} alt="No Categories" className="cardimage" />
              <p className="cardtext">
                It seems there are no categories created yet!
              </p>
              <button className="cardbutton" onClick={handleOpenModal}>
                <span className="cardicon">+</span> Create New
              </button>
            </div>
          </Col>
        </Row>
      )}

      <Row>
        <Col lg="12" md="12">
          <div className="category-header">
            <h1 className="category-title">
              All Category{" "}
              <span className="category-count">({allCategoryCount})</span>
            </h1>
            <button className="create-new-btn" onClick={handleOpenModal}>
              <img src={plus} alt="plusicon" style={{ marginTop: "-2px" }} />{" "}
              Create New
            </button>
          </div>
        </Col>
      </Row>

      <br />
      <Row className="flexnowrap">
        {/* Master Category Section */}
        <div className="parentcontainer" style={{ width: "390px" }}>
          <div className="masterheader">
            <h5 className="spacearoundms">
              <span>Master Category ({filteredMasterCategories.length})</span>
              {/* <button onClick={handleOpenModal} style={{border:"none",background:"none"}}>+</button> */}
            </h5>
          </div>
          <div className="search-filter">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search Master Category"
                value={masterSearchTerm}
                onChange={handleMasterSearchChange}
              />
              <img src={search} alt="search-icon" style={{ width: "22px" }} />
            </div>
          </div>
          {filteredMasterCategories.length > 0 ? (
            filteredMasterCategories.map((category: any) => (
              <div key={category.id} className="category-card">
                <div className="card-content">
                  <div className="card-image-placeholder">
                    <img src={category.image} alt="" className="imgcss" />
                  </div>
                  <div className="card-info">
                    <h3>{category.name}</h3>
                  </div>
                </div>
                <div className="card-contentone">
                  <p style={{ fontSize: "14px", margin: "inherit !important" }}>
                    Created: {category.createdAt}
                  </p>
                  <div className="card-actions">
                    <button
                      className="view-btn"
                      onClick={() =>
                        handleViewDetails(category.id, category.typeId)
                      }
                    >
                      View
                    </button>
                    {category.status === "live" && (
                      <button className="view-btnone">Published</button>
                    )}
                    {category.status === "draft" && (
                      <button
                        className="view-btnone"
                        onClick={() => handlePublishapi(category.id)}
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No Master Categories Available</p>
          )}
        </div>

        {/* Parent Category Section */}
        <div className="parentcontainer" style={{ width: "390px" }}>
          <div className="parentheader">
            <h5 className="spacearound">
              <span>Parent Category ({filteredParentCategories.length})</span>
              <button
                onClick={() => handleOpenModalone(filteredParentCategories)}
                style={{ border: "none", background: "none" }}
              >
                +
              </button>
            </h5>
          </div>
          <div className="search-filter">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search Parent Category"
                value={parentSearchTerm}
                onChange={handleParentSearchChange}
              />
              <img src={search} alt="search-icon" style={{ width: "22px" }} />
            </div>
          </div>
          <div className="scroll">
            {filteredParentCategories.length > 0 ? (
              filteredParentCategories.map((category: any) => (
                <div key={category.id} className="category-card">
                  {/* Category Card Content for Parent Categories */}
                  <div className="card-content">
                    <div className="card-image-placeholder">
                      <img src={category.image} alt="" className="imgcss" />
                    </div>
                    <div className="card-info">
                      <h3 style={{ textTransform: "capitalize" }}>
                        {category.name}
                      </h3>
                      <p style={{ textTransform: "capitalize" }}>
                        {category.masterName}
                      </p>
                    </div>
                    <Dropdown align="end">
                      <Dropdown.Toggle
                        variant="link"
                        id="dropdown-basic"
                        style={{ border: "none", background: "transparent" }}
                      ></Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleEditCategory(category.id)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleDeleteCategory(category.id)}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="card-contentone">
                    <p
                      style={{ fontSize: "14px", margin: "inherit !important" }}
                    >
                      Created: {category.createdAt}
                    </p>
                    <div className="card-actions">
                      <button
                        className="view-btn"
                        onClick={() =>
                          handleViewDetails(category.id, category.typeId)
                        }
                      >
                        View
                      </button>
                      {category.status === "live" && (
                        <button className="view-btnone">Published</button>
                      )}
                      {category.status === "draft" && (
                        <button
                          className="view-btnone"
                          onClick={() => handlePublishapi(category.id)}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No Parent Categories Available</p>
            )}
          </div>
        </div>

        {/* Sub Category Section */}
        <div className="parentcontainer" style={{ width: "390px" }}>
          <div className="subheader1">
            <h5 className="spacearound">
              <span>Sub Category ({filteredSubCategories.length})</span>
              {/* <button onClick={handleOpenModal} style={{border:"none",background:"none"}}>+</button> */}
              <button
                onClick={() => handleOpenModalone(filteredSubCategories)}
                style={{ border: "none", background: "none" }}
              >
                +
              </button>
            </h5>
          </div>
          <div className="search-filter">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search Sub Category"
                value={subSearchTerm}
                onChange={handleSubSearchChange}
              />
              <img src={search} alt="search-icon" style={{ width: "22px" }} />
            </div>
          </div>
          <div className="scroll">
            {filteredSubCategories.length > 0 ? (
              filteredSubCategories.map((category: any) => (
                <div key={category.id} className="category-card">
                  <div className="card-content">
                    <div className="card-image-placeholder">
                      <img src={category.image} alt="" className="imgcss" />
                    </div>
                    <div className="card-info">
                      <h3 style={{ textTransform: "capitalize" }}>
                        {category.name}
                      </h3>
                      <p style={{ textTransform: "capitalize" }}>
                        {category.masterName}, {category.parentName}
                      </p>
                    </div>
                    <Dropdown align="end">
                      <Dropdown.Toggle
                        variant="link"
                        id="dropdown-basic"
                        style={{ border: "none", background: "transparent" }}
                      ></Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleEditCategory(category.id)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleDeleteCategory(category.id)}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="card-contentone">
                    <p
                      style={{ fontSize: "14px", margin: "inherit !important" }}
                    >
                      Created: {category.createdAt}
                    </p>
                    <div className="card-actions">
                      <button
                        className="view-btn"
                        onClick={() =>
                          handleViewDetails(category.id, category.typeId)
                        }
                      >
                        View
                      </button>
                      {category.status === "live" && (
                        <button className="view-btnone">Published</button>
                      )}
                      {category.status === "draft" && (
                        <button
                          className="view-btnone"
                          onClick={() => handlePublishapi(category.id)}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No Sub Categories Available</p>
            )}
          </div>
        </div>

        {/* Child Category Section */}
        <div className="parentcontainer" style={{ width: "390px" }}>
          <div className="childheader">
            <h5 className="spacearound">
              <span>Child Category ({filteredChildCategories.length})</span>
              <button
                onClick={() => handleOpenModalone(filteredChildCategories)}
                style={{ border: "none", background: "none" }}
              >
                +
              </button>
            </h5>
          </div>
          <div className="search-filter">
            <div className="search-barchild">
              <input
                type="text"
                placeholder="Search Child Category"
                value={childSearchTerm}
                onChange={handleChildSearchChange}
              />
              <img src={search} alt="search-icon" style={{ width: "22px" }} />
            </div>
            <button className="cardbuttonchild" onClick={handleChildOpenModal}>
              <span className="cardicon">+</span> Create Child Label
            </button>
          </div>

          <div className="scroll">
            {filteredChildCategories.length > 0 ? (
              filteredChildCategories.map((category: any) => (
                <div key={category.id} className="category-card">
                  <div className="card-content">
                    <div className="card-image-placeholder">
                      <img src={category.image} alt="" className="imgcss" />
                    </div>
                    <div className="card-info">
                      <h3 style={{ textTransform: "capitalize" }}>
                        {category.name}
                      </h3>
                      <p style={{ textTransform: "capitalize" }}>
                        {category.masterName}
                        {category.parentName ? `, ${category.parentName}` : ""}
                        {category.subName ? `, ${category.subName}` : ""}
                      </p>
                    </div>
                    <div>
                      <img src={plusicon}  onClick={() => handleAddChildLabel(category)} alt="addicon" />
                    </div>
                    <Dropdown align="end">
                      <Dropdown.Toggle
                        variant="link"
                        id="dropdown-basic"
                        style={{ border: "none", background: "transparent" }}
                      ></Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleChildEditCategory(category.id)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleChilcDeleteCategory(category.id)}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="card-contentone">
                    <p
                      style={{ fontSize: "14px", margin: "inherit !important" }}
                    >
                      Created: {category.createdAt}
                    </p>
                    <div className="card-actions">
                      <button
                        className="view-btn"
                        onClick={() => handleViewPage(category.id)}
                      >
                        View
                      </button>
                      {category.status === "live" && (
                        <button className="view-btnone">Published</button>
                      )}
                      {category.status === "draft" && (
                        <button
                          className="view-btnone"
                          onClick={() => handlePublishapi(category.id)}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No Child Categories Available</p>
            )}
          </div>
        </div>
        <br />
      </Row>

      {isModalOpen && (
        <>
          <CategoryModel
            onClose={closeModal}
            editData={EditCatData}
            imgupdate={imgUpdateStatus}
            plusId={catIdPlus}
            labelAddData={labelAddData}

          />
        </>
      )}

      {isChildModalOpen && (
        <>
          <CategoryChildModel
            closeChildModal={closeChildModal}
            editData={ChildEditCatData}
            imgupdate={imgUpdateStatus}
          />
        </>
      )}
    </div>
  );
};

export default Category;
