import React, { useEffect, useState } from "react";
import plus from "../../assets/plus.svg";
import UploadIcon from "../../assets/icons/upload-icon.svg";
import remove from "../../assets/remove.svg";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { callApi } from "../utills/api";
import rarrow from "../../assets/rarrow.svg";
import backicon from "../../assets/back.svg";
import CategoryModel from "./categorymodel";
import { showError, showSuccess } from "../utills/sweetAlert";
import "../auth/Elements3.css";

interface MeasurementItem {
  typeId: string;
  categoryId: string;
  id: string;
  title: string;
  labelName: string;
  labelImage?: string; // Add this to store image URL
  imageData?: string; // Add this to store base64 image data
  imageType?: string; // Add this to store image type
}

const Mesurementlabel = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {};
  const { typeid } = location.state || {};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [EditCatData, setEditCatData] = useState<any>({});
  const[labelAddData,setlabelAddData] = useState<any>({});

  
  const [measurements, setMeasurements] = useState<
    Record<string, MeasurementItem[]>
  >({});
  const [deletedIds, setDeletedIds] = useState<string[]>([]);

  useEffect(() => {
    CategorypageShowApi();
    console.log("typeid-->",typeid);
    
  }, [data,typeid]);

  const CategorypageShowApi = () => {
    const req = { categoryId: data };
    callApi("POST", "measurementLabelSelect.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response && response.data) {
          const transformedMeasurements: Record<string, MeasurementItem[]> = {};
          Object.keys(response.data).forEach((category) => {
            const items = response.data[category];
            items.forEach((item: MeasurementItem) => {
              if (!transformedMeasurements[category]) {
                transformedMeasurements[category] = [];
              }
              transformedMeasurements[category].push(item);
            });
          });
          setMeasurements(transformedMeasurements);
        }
      })
      .catch((err: any) => {
        console.error('API call error:', err.response ? err.response.data.error : err.message);
        showError("Failed",err.response ? err.response.data.error : err.message);
      });


      let reqest = {
        categoryId: data
      };
      callApi("POST", "categorySingleSelect.php", reqest)
        .then((res) => res.data)
        .then((response) => {
          if (response) {
            setEditCatData("");
            console.log("EditCatData--->",EditCatData);
          }
        })
        .catch((err: any) => {
          console.error('API call error:', err.response ? err.response.data.error : err.message);
          showError("Failed",err.response ? err.response.data.error : err.message);
        });
  };

  const handleInputChange = (
    category: string,
    value: string,
    index: number
  ) => {
    const updatedMeasurements = { ...measurements };
    updatedMeasurements[category][index].labelName = value;
    setMeasurements(updatedMeasurements);
  };

  const handleRemoveItem = (category: string, index: number) => {
    const updatedMeasurements = { ...measurements };
    const removedItem = updatedMeasurements[category][index];

    if (removedItem.id) {
      setDeletedIds((prev) =>
        prev.includes(removedItem.id) ? prev : [...prev, removedItem.id]
      );
    }

    updatedMeasurements[category].splice(index, 1);
    setMeasurements(updatedMeasurements);
  };

  const handleAddMore = (category: string) => {
    const updatedMeasurements = { ...measurements };
    const firstItemInCategory = updatedMeasurements[category][0]; 
    console.log("firstItemInCategory-->",firstItemInCategory);
    
    updatedMeasurements[category] = updatedMeasurements[category] || [];
    updatedMeasurements[category].push({
      id: "",
      title: category,
      labelName: "",
      categoryId: firstItemInCategory?.categoryId || "", 
      typeId: firstItemInCategory?.typeId || "",
    });
    setMeasurements(updatedMeasurements);
  };

  const handleMeasurementSave = () => {
    const labels = Object.entries(measurements).flatMap(([category, items]) =>
      items.map((item: MeasurementItem) => ({
        id: item.id,
        title: category,
        labelName: item.labelName,
        categoryId:item.categoryId,
        typeId:item.typeId
      }))
    );

    const req = {
      id: data,
      typeId: typeid,
      labels,
      deletedIds,
    };

    callApi("POST", "categoryLableUpdate.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          CategorypageShowApi();
          showSuccess("Success!", "Measurements Updated Successfully!");
        }
      })
      .catch((err: any) => {
        console.error('API call error:', err.response ? err.response.data.error : err.message);
        showError("Failed",err.response ? err.response.data.error : err.message);
      });
  };

  const handleImageChange = (category: string, index: number, file: File) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const updatedMeasurements = { ...measurements };
      updatedMeasurements[category][index].imageData = reader.result as string; // Base64 data
      updatedMeasurements[category][index].imageType = file.type.split("/")[1]; // Extract image type (e.g., jpeg, png)
      setMeasurements(updatedMeasurements);

      // Call API to upload image
      const req = {
        id: updatedMeasurements[category][index].id || "new", // Use item id or "new" for new items
        attachments: [
          {
            deleteStatus: false,
            imageType: updatedMeasurements[category][index].imageType,
            imageData: updatedMeasurements[category][index].imageData,
          },
        ],
      };

      callApi("POST", "labelImageUpdate.php", req)
        .then((res) => res.data)
        .then((response) => {
          CategorypageShowApi();
          console.log("Image uploaded successfully:", response);
        })
        .catch((err: any) => {
          console.error('API call error:', err.response ? err.response.data.error : err.message);
          showError("Failed",err.response ? err.response.data.error : err.message);
        });
    };

    reader.readAsDataURL(file); // Convert image to Base64
  };

  const handleRemoveImage = (category: string, index: number) => {
    const updatedMeasurements = { ...measurements };
    updatedMeasurements[category][index].labelImage = undefined; // Remove the image
    setMeasurements(updatedMeasurements);
  };

  const backto = () => {
    navigate("/layout/category");
  };

  const categoryModel = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    CategorypageShowApi();
  };

  const handleViewSizeDetails = () => {
    navigate("/layout/measurementsize", { state: { data ,typeid} });
  };

  return (
    <main className="">
      <Row>
        <Col lg="12" md="12">
          <div className="category-header">
            <h1 className="category-title">
              <img src={backicon} alt="" onClick={backto} />
              <span className="category-count">Create Measurements</span>
            </h1>
            <button className="create-new-btn" onClick={categoryModel}>
              <img src={plus} alt="plusicon" style={{ marginTop: "-2px" }} />{" "}
              Create New
            </button>
          </div>
        </Col>
      </Row>

      {/* Measurements Table */}
      {Object.keys(measurements).length > 0 ? (
      <Row>
        {Object.entries(measurements).map(([category, items]) => (
          <Col md={6} key={category} style={{ margin: "2% 0%" }}>
            <h3>{category.charAt(0).toUpperCase() + category.slice(1)}</h3>
            {items.map((item, index) => (
              <div className="unique-measurement-item" key={index}>
                <Form.Control
                  type="text"
                  style={{ width: "60%" }}
                  value={item.labelName}
                  onChange={(e) =>
                    handleInputChange(category, e.target.value, index)
                  }
                  className="unique-input-field"
                />
                <button
                  onClick={() => handleRemoveItem(category, index)}
                  className="unique-remove-size-btn"
                >
                  <img src={remove} alt="removeicon" />
                </button>

                {/* Conditionally render the image upload input or image tag */}
                {item.labelImage ? (
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <img
                      src={`https://seekreative.zerame.com/api/uploads/${item.labelImage}`}
                      alt="Uploaded"
                      className="meslabimg"
                    />
                    <span
                      className="meslabelimgclose"
                      onClick={() => handleRemoveImage(category, index)}
                    >
                      &times;
                    </span>
                  </div>
                ) : item.id ? (
                  <div className="measurementuploadimage">
                    <input
                      type="file"
                      id={`uploadInput-${category}-${index}`}
                      accept="image/*"
                      onChange={(e) =>
                        handleImageChange(
                          category,
                          index,
                          e.target.files?.[0] as File
                        )
                      }
                    />
                    <label
                      htmlFor={`uploadInput-${category}-${index}`}
                      className="designupload-label"
                    >
                      <div className="designupload-contentone">
                        <img
                          src={UploadIcon}
                          alt="fileadd"
                          className="designupload-icon"
                        />
                        <span>Image</span>
                      </div>
                    </label>
                  </div>
                ) : null}
              </div>
            ))}
            <Button
              className="unique-add-more-button"
              onClick={() => handleAddMore(category)}
            >
              + Add More
            </Button>
          </Col>
        ))}
      </Row>
) : (
  <Col md={12}>
    <p>No Data found.</p>
  </Col>
)}
      {Object.keys(measurements).length > 0 && (
      <div className="unique-pagination-section">
        <button
          className="savecontinuebutton"
          onClick={() => handleMeasurementSave()}
        >
          <span className="">Save As</span>
        </button>

        <button
          className="savecontinuebutton"
          onClick={() => handleViewSizeDetails()}
        >
          <span className="">
            Next <img src={rarrow} alt="rarrow" />
          </span>
        </button>
      </div>
      )}


      {isModalOpen && (
        <>
          <CategoryModel onClose={closeModal}  editData={EditCatData}
            labelAddData={labelAddData}
             />
        </>
      )}
    </main>
  );
};

export default Mesurementlabel;
